// import { useState } from 'react';
// import { Button, Modal } from '@mantine/core';

// export function ConfirmationModal(message:string): Promise<boolean> {
//   const [opened, setOpened] = useState(false);

//   return new Promise((resolve) => {
//     return (
//       <>
//         <Modal
//           opened={opened}
//           onClose={() => setOpened(false)}
//           title="Bestätigen"
//         >
//           <p>{message}</p>
//           <Button onClick={() => {
//             setOpened(false);
//             resolve(true);
//           }}>
//             Bestätigen
//           </Button>
//           <Button onClick={() => {
//             setOpened(false);
//             resolve(false);
//           }}>
//             Abbrechen
//           </Button>
//         </Modal>
//       </>
//     );
//   });
// }

// import { useState } from 'react';
import { Button, Modal } from '@mantine/core';

export function ConfirmationModal({ message, opened, onConfirm, onCancel }: { message: string, opened: boolean, onConfirm: () => void, onCancel: () => void }) {
  return (
    <Modal
      opened={opened}
      onClose={onCancel}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
      centered
    >
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', flexDirection: 'column' }}>
        <h2 style={{ textAlign: 'center' }}>Bestätigen</h2>
        <p style={{ textAlign: 'center', marginBottom: '3%'}}>{message}</p>
        <Button style={{marginRight: '0%'}} onClick={onConfirm}>
          Bestätigen
        </Button>
        <Button onClick={onCancel}>
          Abbrechen
        </Button>
      </div>
    </Modal>
  );
}
import { showNotification } from "@mantine/notifications";
import { FloorPlan } from "../objects/FloorPlan";
import { Wall } from "../objects/Walls/Wall";
import { Action } from "./Action";

export class DeleteWallAction implements Action {

    private wall:Wall; //TODO: Add node data pt undo/redo
    private receiver:FloorPlan;
    private leftid: number;
    private rightid: number;

    constructor(wall:Wall) {
        this.wall = wall;
        this.leftid = wall.leftNode.getId();
        this.rightid = wall.rightNode.getId();
        this.receiver = FloorPlan.Instance;
    }

    public execute(): void {
        this.receiver.actions.push(this);
        let allwallnode = FloorPlan.Instance.getWallNodeSeq().getWallNodes();
        let size = allwallnode.size
        if(size > this.rightid){
            return showNotification({
                title: 'Not permitted',
                color: 'red',
                message: 'Es darf nur die letzte linie gelöscht werden!!!',
              })
        }else {
            
        debugger
        this.receiver.removeWall(this.wall);
        this.receiver.removeWallNode(this.rightid);
        }
    }
    public undo() {
        this.receiver.getWallNodeSeq().addWall(this.leftid, this.rightid, false);
    }

    public redo() {
        this.execute();
    }
}


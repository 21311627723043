import React, { useEffect, useState } from 'react';
import { getCatalog, addFolderToParent, gettoken, deleteFolder, deletePlan, addPlanToParent } from '../api/api-client';
import { Container, Text, Modal, Grid, Col, Image, Group, Button, ActionIcon, TextInput } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import IconFolder from '../res/folder.svg';
import { ArrowLeft } from 'tabler-icons-react';
import Login from './Login';
import { FloorPlan } from "../editor/editor/objects/FloorPlan";
import { showNotification } from '@mantine/notifications';
import { LoadAction } from '../editor/editor/actions/LoadAction';

const useStyles = {
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    padding: '20px',
    justifyContent: 'center',
  },
  item: {
    flex: '1 1 calc(25% - 20px)', // Four items per row with gap
    maxWidth: '150px',
    textAlign: 'center',
    cursor: 'pointer',
    padding: '10px',
    backgroundColor: '#f0f0f0', // Light grey background
    borderRadius: '10px',
    position: 'relative', // For positioning the delete icon
    transition: 'transform 0.2s',
  },
  icon: {
    width: '48px',
    height: '48px',
    marginBottom: '10px',
  },
  deleteIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
  },
  selected: {
    borderRadius: '5px',
  },
};

interface Folder {
  id: string;
  name: string;
  type: string;
  visible: boolean;
  folders: string[];
  plans: string[];
  __v: number;
}

interface Plan {
  id: string;
  name: string;
  planPath: string; // Assuming this is a JSON string
  image: string; // Assuming this is a base64 encoded image string
  __v: number;
}

interface CatalogData {
  folders: Folder[];
  plans: Plan[];
}

export function Catalog({ opened, onCancel }: { opened: boolean; onCancel: () => void }) {
  const [folders, setFolders] = useState<Folder[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [navigationStack, setNavigationStack] = useState<string[]>([]); // Stack to keep track of navigation history
  const [currentFolder, setCurrentFolder] = useState<Folder | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginOpened, setLoginOpened] = useState(false);
  const [token, setToken] = useState<string | null>(null); // Add token state
  const [folderDialogOpen, setFolderDialogOpen] = useState(false); // State for folder dialog
  const [newFolderName, setNewFolderName] = useState(''); // State for new folder name
  const [planDialogOpen, setPlanDialogOpen] = useState(false); // State for plan dialog
  const [newPlanName, setNewPlanName] = useState(''); // State for new plan name

  useEffect(() => {
    const fetchCatalog = async () => {
      const catalogData: CatalogData = await getCatalog();
      setFolders(catalogData.folders);
      setPlans(catalogData.plans);
      // Set the initial folder to the first one in the list
      if (catalogData.folders.length > 0) {
        setCurrentFolder(catalogData.folders[0]);
        setNavigationStack([catalogData.folders[0].id]); // Initialize the stack with the first folder
      }
    };
    fetchCatalog();
  }, []);

  const findPlanById = (planId: string) => {
    return plans.find(plan => plan.id === planId);
  }

  const handleItemClick = async (item: any) => {
    setSelectedItem(item.id);
    if (item.planPath) {
      console.log("Plan clicked: " + item.name);
      await loadPlanTemplate(item.planPath);
      onCancel();
    } else {
      setCurrentFolder(item as Folder);
      fetchSubcategories(item.id);
    }
  };


  const loadPlanTemplate = async (planPath: string) => {
    try {
        const loading = new LoadAction(planPath); // Call the load action with the parsed plan path (assuming it's a JSON string
        loading.execute(); // Execute the load action
      } catch (err) {
        console.error('Failed to load plan template:', err);
    }
};

  const fetchSubcategories = (categoryId: string) => {
    // Since we are no longer fetching from an API, just update the folder view based on existing data
    setNavigationStack(prevStack => [...prevStack, categoryId]);
    const newFolder = folders.find(folder => folder.id === categoryId);
    setCurrentFolder(newFolder || null);
  };

  const handleBackClick = () => {
    if (navigationStack.length <= 1) return; // Prevent going back if we are at the root

    setNavigationStack((prevStack) => {
      const newStack = [...prevStack];
      newStack.pop(); // Pop the last category from the stack
      const newCurrentFolderId = newStack[newStack.length - 1];
      const newCurrentFolder = folders.find(folder => folder.id === newCurrentFolderId) || null;
      setCurrentFolder(newCurrentFolder);
      return newStack;
    });
  };

  const handleLogin = async (username: string, password: string) => {
    try {
      const token = await gettoken(username, password);
      if (token !== null) {
        setIsLoggedIn(true);
        setToken(token);
      }
    } catch (err) {
      console.error("Login failed:", err);
    }
  };

  const handleDelete = async (itemId: string, isFolder: boolean) => {
    try {
        if (isFolder) {
            await deleteFolder(itemId, token);
            setFolders(folders.filter(folder => folder.id !== itemId));

            // Update the current folder's folders array
            if (currentFolder) {
                setCurrentFolder({
                    ...currentFolder,
                    folders: currentFolder.folders.filter(id => id !== itemId)
                });
            }
        } else {
            await deletePlan(itemId, token);
            setPlans(plans.filter(plan => plan.id !== itemId));

            // Update the current folder's plans array
            if (currentFolder) {
                setCurrentFolder({
                    ...currentFolder,
                    plans: currentFolder.plans.filter(id => id !== itemId)
                });
            }
        }
    } catch (err) {
        console.error("Failed to delete the item:", err);
    }
};

  const handleCreateFolder = () => {
    setFolderDialogOpen(true);
  };

  const handleSaveFolder = async () => {
    if (!currentFolder) return;

    const folderData = {
      name: newFolderName,
      visible: true,
      folders: [],
      plans: []
    };

    try {
      const newFolder = await addFolderToParent(currentFolder.id, folderData, token);
      console.log('Folder created:', newFolder);
      setFolderDialogOpen(false); // Close the dialog
      setFolders([...folders, newFolder]);

      // Update the current folder's folders array
      setCurrentFolder({
        ...currentFolder,
        folders: [...currentFolder.folders, newFolder.id]
      });
    } catch (err) {
      console.error(err);
    }
  };


  const createPlanNotification = {
      title: "❌❌❌ FEHLER ❌❌❌ ",
      message: "Du musst zuerst auch was Zeichnen bevor du speichern kannst!!!!",
      color: "red",
      autoClose: true,
  }
  const handleCreatePlan = () => {
    if(FloorPlan.Instance.getabroll() <= 20){
      return showNotification(createPlanNotification);
    }
    setPlanDialogOpen(true);
  };

  const handleSavePlan = async () => {
    if (!currentFolder) return;
    try {
        const planPath = FloorPlan.Instance.saveAsTemplate(); // Rufe die Funktion auf, um den Planpfad zu erhalten
        const image = await FloorPlan.Instance.getImageForTemplate(); // Rufe die Funktion auf, um das Bild zu erhalten und konvertiere es in Base64

        const planData = {
            name: newPlanName,
            planPath: planPath,
            image: image
        };

        const newPlan = await addPlanToParent(currentFolder.id, planData, token);
        console.log('Plan created:', newPlan);
        setPlanDialogOpen(false); // Close the dialog
        setPlans([...plans, newPlan]);

        // Update the current folder's plans array
        setCurrentFolder({
            ...currentFolder,
            plans: [...currentFolder.plans, newPlan.id]
        });
    } catch (err) {
        console.error(err);
    }
};

  const renderItems = (items: (Folder | Plan)[], showDeleteIcon: boolean) => {
    return items.map((item) => {
      const isFolder = (item as Folder).type === "folder";
      let itemIcon;

      if (isFolder) {
        itemIcon = <Image src={IconFolder} alt="Folder" style={useStyles.icon} />;
      } else {
        const imageSrc = `data:image/png;base64,${(item as Plan).image}`;
        itemIcon = <Image src={imageSrc} alt="File" style={{ ...useStyles.icon, height: "auto", width: "40%" }} />;
      }

      return (
        <Col span={4} key={item.id} styles={useStyles.item} onClick={() => handleItemClick(item)}>
          {showDeleteIcon && (
            <ActionIcon onClick={() => handleDelete(item.id, isFolder)}>
              <IconTrash size={16} />
            </ActionIcon>
          )}
          <Group direction="column" align="center" spacing="xs" style={{ width: '100%' }}>
            {itemIcon}
            <Text style={{ wordBreak: 'normal' }}>{item.name}</Text>
          </Group>
        </Col>
      );
    });
  };

  const renderFolderContents = (folder: Folder) => {
    const subfolders = folders.filter(f => folder.folders.includes(f.id));
    const folderPlans = folder.plans.map(planId => findPlanById(planId)).filter(p => p !== undefined);

    return (
      <>
        <Grid styles={useStyles.grid}>
          {renderItems(subfolders, isLoggedIn)}
          {renderItems(folderPlans, isLoggedIn)}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Modal
        opened={opened}
        centered
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClose={onCancel}
        size="70%"
      >
        <Button
          leftIcon={<ArrowLeft size={24} />}
          variant="subtle"
          onClick={handleBackClick}
          style={{ marginBottom: '20px' }}
          disabled={navigationStack.length <= 1} // Disable the button if at root
        >
          Back
        </Button>
        <Container size="lg" style={{ padding: '1rem' }}>
          <Text size="xl" weight={500} style={{ marginBottom: 20, textAlign: 'center', fontSize: '1.75rem' }}>
            Profil Katalog
          </Text>
          <Grid styles={useStyles.grid}>
            {currentFolder ? (
              renderFolderContents(currentFolder)
            ) : (
              <Text size="md" style={{ textAlign: 'center' }}>
                No folder selected or folder is empty.
              </Text>
            )}
          </Grid>
          {isLoggedIn && (
            <Group position="center" spacing="md" mt="md">
              <Button onClick={handleCreateFolder}>Create New Folder</Button>
              <Button onClick={handleCreatePlan}>Save Template</Button>
            </Group>
          )}
        </Container>
      </Modal>
      <Modal
        opened={folderDialogOpen}
        onClose={() => setFolderDialogOpen(false)}
        title="Create New Folder"
        centered
      >
        <TextInput
          label="Folder Name"
          value={newFolderName}
          onChange={(event) => setNewFolderName(event.currentTarget.value)}
        />
        <Group position="center" mt="md">
          <Button onClick={handleSaveFolder}>Save</Button>
        </Group>
      </Modal>
      <Modal
        opened={planDialogOpen}
        onClose={() => setPlanDialogOpen(false)}
        title="Vorlage Speichern, vergiss aber nicht das die Zeichnung nicht automatisch geleert wird. Bitte leere dein Zeichenfeld um eine neue Zeichnung zu beginnen! "
        centered
      >
        <TextInput
          label="Vorlagenname"
          value={newPlanName}
          onChange={(event) => setNewPlanName(event.currentTarget.value)}
        />
        <Group position="center" mt="md">
          <Button onClick={handleSavePlan}>Save</Button>
        </Group>
      </Modal>
      <Button onClick={() => setLoginOpened(true)} style={{ position: 'fixed', top: 20, right: 20 }}>
        {isLoggedIn ? 'Logout' : 'Login'}
      </Button>
      {!isLoggedIn && (
        <Login opened={loginOpened} onClose={() => setLoginOpened(false)} onLogin={handleLogin} />
      )}
    </>
  );
}


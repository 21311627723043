import { FloorPlan } from "../objects/FloorPlan";
import { WallNode } from "../objects/Walls/WallNode";
import { Action } from "./Action";

// Add wall between two nodes of FloorPlan
export class AddWallAction implements Action {
    
    private leftNode:number;
    private rightNode: number;
    private receiver:FloorPlan;
    private removedRightNode: WallNode | null = null;
    private removedx: number;
    private removedy: number;
    private removedid: number;
    private removedAllowed: boolean;
    private leftid: number;
   

    constructor(left:WallNode, right:WallNode) {
        this.leftNode = left.getId();
        this.rightNode = right.getId();
        this.receiver = FloorPlan.Instance;
    }

    public execute() {
        
        this.receiver.actions.push(this);
        return this.receiver.getWallNodeSeq().addWall(this.leftNode, this.rightNode, this.removedAllowed)
    }

    public undo() {
        let allwalls = FloorPlan.Instance.getWallNodeSeq().getWalls();
        let wall = allwalls[allwalls.length - 1];
        this.receiver.getWallNodeSeq().removeWall(wall.leftNode.getId(), wall.rightNode.getId());
        this.removedRightNode = wall.rightNode;
        this.leftid = wall.leftNode.getId();
        this.removedx = wall.rightNode.getPosition().x;
        this.removedy = wall.rightNode.getPosition().y;
        this.removedid = wall.rightNode.getId();
        this.removedAllowed = wall.isAllowed;
        this.receiver.getWallNodeSeq().deleteSvg(wall.leftNode.getId());
        this.receiver.getWallNodeSeq().remove(wall.rightNode.getId());
    }

    public redo() {
        let newoldnode;
        if (this.removedRightNode) {
            
            newoldnode = this.receiver.getWallNodeSeq().addNode(this.removedx, this.removedy, this.removedid, this.removedAllowed);
        }
        if(newoldnode){
            this.rightNode = newoldnode.getId();
        }
        if(!this.leftNode){
            
            this.leftNode = this.receiver.getWallNodeSeq().getWalls()[this.receiver.getWallNodeSeq().getWalls().length].leftNode.getId();
        }
        this.leftNode = this.leftid;
        this.execute();
    }
}
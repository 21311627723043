import { InteractionEvent } from "pixi.js";
import { euclideanDistance } from "../../../helpers/EuclideanDistance";
import { Point } from "../../../helpers/Point";
import {  history, METER } from "../constants";
import { FloorPlan } from "../objects/FloorPlan";
import { TransformLayer } from "../objects/TransformControls/TransformLayer";
import { WallNode } from "../objects/Walls/WallNode";
import { AddWallAction } from "./AddWallAction";
import { Preview } from "./MeasureToolManager";
import { ActionHistory } from "./ActionHistory";

// tracks current action data
export class AddWallManager {

    private actionHistory: ActionHistory;

    private static instance: AddWallManager;

    public previousNode: WallNode;

    public preview: Preview;

    private constructor() {
        this.previousNode = undefined;
        this.preview = new Preview();
        this.actionHistory = history

    }

    // checks if step is valid
    public checkStep(coords:Point) {
        if (this.previousNode === undefined) {
        // eslint-disable-next-line
            for (let [id,node] of FloorPlan.Instance.getWallNodeSeq().getWallNodes()) {
                if (euclideanDistance(coords.x, node.x, coords.y, node.y) < 0.3 * METER) {
                    return false;
                }
            }
            return true;
        }
        if (euclideanDistance(coords.x, this.previousNode.x, coords.y, this.previousNode.y) < 0.3 * METER) {
            return false;
        }

        // Check if the new node is on an already drawn wall
        for (let wall of FloorPlan.Instance.getWallNodeSeq().getWalls()) {
            if (wall.isPointOnWall(coords)) {
                return false;
            }
        }

        return true;
    }
    public step(node: WallNode) {
        // first click. set first node
        if (node === undefined) {
            return;
        }
        if (this.previousNode === undefined) {
            this.previousNode = node;
            this.preview.set(this.previousNode.position)
            return;
        }
    
        // double click. end chain
        if (this.previousNode.getId() === node.getId()) {
            this.previousNode = undefined;
            this.preview.set(undefined)
            return;
        }
    
        // Check if the new node is on an already drawn wall
        for (let wall of FloorPlan.Instance.getWallNodeSeq().getWalls()) {
            if (wall.isPointOnWall(node.position)) {
                return; // If the new node is on an existing wall, do not add it and return
            }
        }
    
        //new node on screen
        let wallAction = new AddWallAction(this.previousNode, node);
        ActionHistory.Instance.execute(wallAction);
        this.preview.set(node.position)
    
        this.previousNode = node;
        this.preview.set(this.previousNode.position)
    }

    public changePreview(node: WallNode) {
        this.preview.set(node.position);
        this.previousNode = node;
        // this.preview.set(this.previousNode.position)
    }
    public updatePreview(ev:InteractionEvent) {
        this.preview.updatePreview(ev, true);

    }
    public unset() {
        this.previousNode = undefined;
        this.preview.set(undefined);
    }
    public static get Instance() {
        return this.instance || (this.instance = new this());
    }

    public resetTools() {
        TransformLayer.Instance.deselect();
        this.unset();
    }
}


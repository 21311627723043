import { Icon as TablerIcon } from '@tabler/icons-react';
import { createStyles, Tooltip, UnstyledButton } from '@mantine/core';
import { IconRulerMeasure } from '@tabler/icons-react';
import CatalogueIcon from './icons/cataloge';


const useStyles = createStyles((theme) => ({
    link: {
      width: 40,
      height: 40,
      borderRadius: theme.radius.md,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
      },
    },
  
    active: {
      '&, &:hover': {
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
            : theme.colors[theme.primaryColor][0],
        color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 7],
      },
    },
  }));

interface NavbarLinkProps {
    icon?: TablerIcon;
    label?: string;
    active?: boolean;
    onClick?(): void;
    newicon?: string;
  }

export function NavbarLink({ icon: Icon, label, active, onClick, newicon }: NavbarLinkProps) {
    const { classes, cx } = useStyles();
    if(label === "Katalog öffnen") {
      return (
        <Tooltip label={label} position="right" withArrow transitionDuration={0}>
          <UnstyledButton onClick={onClick} className={cx(classes.link, { [classes.active]: active })}>
            <CatalogueIcon />
          </UnstyledButton>
        </Tooltip>
      );
    }
    // if(label === "Abkanten") {
    //   return (
    //     <Tooltip label={label} position="right" withArrow transitionDuration={0}>
    //       <UnstyledButton onClick={onClick} className={cx(classes.link, { [classes.active]: active })}>
    //         <RoundingIcon />
    //       </UnstyledButton>
    //     </Tooltip>
    //   );
    // }
    if(newicon === undefined) {
      return (
        <Tooltip label={label} position="right" withArrow transitionDuration={0}>
          <UnstyledButton onClick={onClick} className={cx(classes.link, { [classes.active]: active })}>
            <Icon />
          </UnstyledButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip label={label} position="right" withArrow transitionDuration={0}>
          <UnstyledButton onClick={onClick} className={cx(classes.link, { [classes.active]: active })}>
            <IconRulerMeasure />
          </UnstyledButton>
        </Tooltip>
      );
    }
  }
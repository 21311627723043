import { Graphics, InteractionEvent } from "pixi.js";
import { isMobile } from "react-device-detect";
import { Point } from "../../../../helpers/Point";
import { TransformLayer } from "./TransformLayer";

export enum HandleType {
    Horizontal,
    Vertical,
    HorizontalVertical,
    Rotate,
    Move
}

export interface IHandleConfig {
    size?: number,
    color?: number,
    type: HandleType,
    pos?: Point
}

export class Handle extends Graphics {

    private type: HandleType;
    private color: number = 0x000;
    private size: number = 10;

    private active: boolean = false;
    private mouseStartPoint: Point;
    private targetStartPoint: Point;
    private mouseEndPoint: Point;
    private startRotaton: number;
    private startScale: Point;
    private targetStartCenterPoint: Point;
    localCoords: { x: number; y: number; };
    constructor(handleConfig: IHandleConfig) {
        super();
        this.interactive = true;
        if (handleConfig.color) {
            this.color = handleConfig.color;
        }

        if (handleConfig.size) {
            this.size = handleConfig.size;

        }

        this.mouseStartPoint = { x: 0, y: 0 };
        this.targetStartPoint = { x: 0, y: 0 };

        this.startScale = { x: 0, y: 0 };
        this.targetStartCenterPoint = { x: 0, y: 0 };
        this.localCoords = {x:0, y:0};
        this.mouseEndPoint = { x: 0, y: 0 };

        this.type = handleConfig.type;
        this.buttonMode = true;
        this.beginFill(this.color)
            .lineStyle(1, this.color)

        if (isMobile) {
            this.size = this.size * 2.5;
        }
        if (this.type === HandleType.Rotate) {
            this.drawCircle(0, 0, this.size / 1.5).endFill();
            this.pivot.set(this.size / 3, this.size / 3);

        } else {
            this.drawRect(0, 0, this.size, this.size).endFill();
            this.pivot.set(0.5);
        }


        switch (this.type) {
            case HandleType.Move:
                this.cursor = "move";
                break;
            case HandleType.Horizontal:
                this.cursor = "ew-resize";
                break;
            case HandleType.Vertical:
                this.cursor = "ns-resize";
                break;
            case HandleType.HorizontalVertical:
                this.cursor = "nwse-resize";
                break;
            case HandleType.Rotate:
                this.cursor = "wait";
                break;
        }
        if (handleConfig.pos) {
            this.position.set(handleConfig.pos.x, handleConfig.pos.y);
        }

        this.on("pointerdown", this.onMouseDown)
        this.on("pointerup", this.onMouseUp)
        this.on("pointerupoutside", this.onMouseUp)
        this.on("pointermove", this.onMouseMove)
    }

    private onMouseDown(ev: InteractionEvent) {
        if (TransformLayer.dragging) {
            return;
        }
        this.mouseStartPoint.x = ev.data.global.x
        this.mouseStartPoint.y = ev.data.global.y; // unde se afla target la mousedown
        TransformLayer.dragging = true;
        this.active = true;
        // this.target.setSmartPivot(0);
        ev.stopPropagation();
    }

    private onMouseUp(ev: InteractionEvent) {
        TransformLayer.dragging = false;
        this.active = false;
        ev.stopPropagation();
    }


    private onMouseMove(ev: InteractionEvent) {

        if (!this.active || !TransformLayer.dragging) {
            return;
        }
        // unde se afla mouse-ul acum
        this.mouseEndPoint.x = ev.data.global.x;
        this.mouseEndPoint.y = ev.data.global.y;

    }


    /* sets scale and transform */
    public update(pos: Point) {
        this.position.set(pos.x, pos.y)
    }
}

import { Action } from "./Action";

export class ActionHistory {
    private actions: Action[] = [];
    private pointer = -1;
    private static instance: ActionHistory;

    public static get Instance() {
        return this.instance || (this.instance = new this());
    }


    public execute(action: Action) {
        this.actions = this.actions.slice(0, this.pointer + 1);
        this.actions.push(action);
        action.execute();
        this.pointer++;
    }

    public undo() {
        if (this.pointer < 0 || this.pointer > this.actions.length) {
            return;
        }
        this.actions[this.pointer - 1].undo();
        this.pointer--;
    }

    public redo() {
        
        if (this.pointer + 1  === this.actions.length) {
            
            return;
        }
        
        this.pointer++;
        this.actions[this.pointer - 1].redo();
    }
}
import { INodeSerializable } from "./INodeSerializable";

export class FloorSerializable {
    public wallNodes: INodeSerializable[];
    public wallNodeLinks: [number, number[]][];
    
    public constructor() {
        this.wallNodes = [];
        this.wallNodeLinks = [];
    
    }

}
import React, { useState } from 'react';
import { Modal, TextInput, PasswordInput, Button, Group } from '@mantine/core';

interface LoginProps {
  opened: boolean;
  onClose: () => void;
  onLogin: (username: string, password: string) => void;
}

const Login: React.FC<LoginProps> = ({ opened, onClose, onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    onLogin(username, password);
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Login">
      <TextInput
        label="Username"
        placeholder="Username"
        value={username}
        onChange={(event) => setUsername(event.currentTarget.value)}
      />
      <PasswordInput
        label="Password"
        placeholder="Password"
        value={password}
        onChange={(event) => setPassword(event.currentTarget.value)}
        mt="md"
      />
      <Group position="right" mt="md">
        <Button onClick={handleLogin}>Login</Button>
      </Group>
    </Modal>
  );
};

export default Login;

// import saveAs from "file-saver";
import { FloorPlan } from "../objects/FloorPlan";
import { Action } from "./Action";
// import { ClearAction } from "./ClearAction";
// import { AddWallManager } from "./AddWallManager";
// import { PageLayout } from "../../../ui/Layout/PageLayout";

export class SalesforceAction implements Action {

    private receiver: FloorPlan;
    constructor() {
        this.receiver = FloorPlan.Instance;

    }

    public execute() {
        // let data = this.receiver.save();
        // let blob = new Blob([data], { type: "application/json;charset=utf-8" });
        // saveAs(blob, "kantteil.json");
        // let action = new ClearAction();
        // action.execute();
        // AddWallManager.Instance.resetTools()
        try {
            const planPath = FloorPlan.Instance.saveAsTemplate(); // Rufe die Funktion auf, um den Planpfad zu erhalten
            const image = FloorPlan.Instance.getImageForTemplate(); // Rufe die Funktion auf, um das Bild zu erhalten und konvertiere es in Base64
    
            const planData = {
                uuid: FloorPlan.Instance.uuid,
                planPath: planPath,
                image: image
            };

            console.log("plan for salesforce created: " + planData);

            if (window.opener) {
                window.opener.postMessage(planData, '*');
                setTimeout(() => {
                    console.log("Closing Window now")
                    window.close();
                }, 5000);
            } else {
                console.error("window.opener is null. Cannot post message.");
            }
            
            console.log(planData);



        } catch (err) {
            console.error(err);
        }
    }
    public undo() {}
    public redo() {}
}
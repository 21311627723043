import { ActionHistory } from "./actions/ActionHistory";

// how many pixels is a meter
export const METER = 200;
export const WALL_THICKNESS = 0.1*100;
export const INTERIOR_WALL_THICKNESS = 0.075*100;

export const LABEL_OFFSET = 10;

export const history = new ActionHistory();

export enum Modes {
    Idle,
    Dragging,
    Editing,
    Destroy
};

export enum Coord {
    NE,
    E,
    SE,
    S,
    C,
    Horizontal,
    Vertical
};

export enum LabelAxis {
    Horizontal,
    Vertical
}

export enum ToolMode {
    WallMode,
    FurnitureMode,
    ViewMode,
};


export enum Tool {
    WallAdd,
    Edit,
    Remove,
    Measure,
    View
};

import { FloorPlan } from "../objects/FloorPlan";
import { Action } from "./Action";
import { AddWallManager } from "./AddWallManager";

export class ClearAction implements Action {

    private receiver: FloorPlan;
    constructor() {
        this.receiver = FloorPlan.Instance;

    }

    public execute() {
        this.receiver.justclear();
        AddWallManager.Instance.resetTools()

    }
    public undo() {}
    public redo() {}
}
import { autoDetectRenderer, Container, IRendererOptionsAuto } from "pixi.js";
import { Wall } from "./Walls/Wall";
import { Floor } from "./Floor";
import { Serializer } from "../persistence/Serializer";
import { FloorPlanSerializable } from "../persistence/FloorPlanSerializable";
import { Action } from "../actions/Action";
import { useStore } from "../../../stores/EditorStore";
import { Point } from "../../../helpers/Point";
import { showNotification } from "@mantine/notifications";

export class FloorPlan extends Container {

    private static instance: FloorPlan;
    private originalWallData: { wall: Wall, angle: number, length: number }[] = [];
    public selectedWall: Wall;
    private floors: Floor[];
    private visibleLabels: boolean = true;
    private serializer: Serializer;
    public actions: Action[];

    public currentFloor = 0;
    uuid: string;
    private constructor() {
        super();
        this.floors = [];
        this.actions = [];
        this.floors.push(new Floor());
        this.addChild(this.floors[0]);
        this.serializer = new Serializer();
    }
    public static get Instance() {
        return this.instance || (this.instance = new this());
    }

    public get CurrentFloor() {
        return this.currentFloor;
    }

    public set CurrentFloor(floor: number) {
        this.currentFloor = floor;
        useStore.setState({ floor: this.currentFloor })
    }

    public set uuidset (uuid: string) {
        this.uuid = uuid;
        return
    }

    public toggleLabels() {
        this.visibleLabels = !this.visibleLabels;
        this.floors[this.currentFloor].setLabelVisibility(this.visibleLabels);
    }


    public toggleLabel() {
        this.floors[this.currentFloor].setfirstanglelabel();
                 
                
    }

    public storeOriginalWallData() {
        this.originalWallData = this.floors[this.currentFloor].wallNodeSequence.getWalls().map(wall => ({
            wall,
            angle: wall.angle2,
            length: wall.calculateLineWidth()
        }));
    }
    public recalculateWallPositions() {
        this.originalWallData.forEach(({ wall, angle, length }) => {
            if (wall !== this.selectedWall) {
                let dx = length * Math.cos((angle) * (Math.PI / 180));
                let dy = length * Math.sin((angle) * (Math.PI / 180));
                let newRightNodeX = wall.leftNode.position.x + dx;
                let newRightNodeY = wall.leftNode.position.y + dy;

                wall.rightNode.setPositiontest(newRightNodeX, newRightNodeY);
                wall.drawLine();
            }
        });
    }

    public changeFloor(by: number) {

        this.removeChild(this.floors[this.currentFloor]);
        let previousFloor = this.currentFloor;
        this.CurrentFloor += by;
        if (this.floors[this.currentFloor] == null) {
            this.floors[this.currentFloor] = new Floor(null, this.floors[previousFloor]);
        }
        this.floors[this.currentFloor].setLabelVisibility(this.visibleLabels);
        this.addChild(this.floors[this.currentFloor])
    }

    public print() {

        let opts: IRendererOptionsAuto = {
            preserveDrawingBuffer: true
        };

        let renderer = autoDetectRenderer(opts);
        const image = renderer.plugins.extract.image(this)
        let popup = window.open();
        popup.document.body.appendChild(image);
        popup.focus();
        // popup.print();  // deactivated the print function because it made problems, the print popup came earlier then the png
    }

    public getImage(){
        let opts: IRendererOptionsAuto = {
            preserveDrawingBuffer: true
        };

        let renderer = autoDetectRenderer(opts);
        const image = renderer.plugins.extract.image(this)
        return image;
    }
    public getImageForTemplate(): string {
        let opts: IRendererOptionsAuto = {
            preserveDrawingBuffer: true
        };
    
        let renderer = autoDetectRenderer(opts);
        renderer.render(this);
    
        // Create an off-screen canvas to draw the image onto
        const canvas = document.createElement('canvas');
        canvas.width = this.width; // Passe die Breite an
        canvas.height = this.height; // Passe die Höhe an
    
        const context = canvas.getContext('2d');
        if (!context) {
            throw new Error('Failed to get 2D context');
        }
    
        // Draw the image onto the canvas
        context.drawImage(renderer.plugins.extract.canvas(this), 0, 0);
    
        // Get the Base64-encoded image
        const imageBase64 = canvas.toDataURL('image/png').split(',')[1]; // Entferne den Präfix
        console.log(imageBase64);
    
        return imageBase64;
    }
    public getabroll() {
        let allwalls = FloorPlan.Instance.getWallNodeSeq().getWalls();
        let abroll = allwalls.reduce((sum, wall) => sum + wall.distance, 0);
        return abroll;
    }

    public save() {

        let floorPlan = this.serializer.serialize(this.floors);
        let abroll = this.getabroll();
        let object = JSON.parse(floorPlan);
        let allwalls = FloorPlan.Instance.getWallNodeSeq().getWalls();
        let visibleAngleLabelCount = 0;
        for (let wall of allwalls) {
            if (wall.anglelabel.visible) {
                visibleAngleLabelCount++;
            }
        }
        if(visibleAngleLabelCount >= 1) {
        object.kantungen = visibleAngleLabelCount;
        }
        object.abroll = abroll;

        if( this.uuid !== null ) {
            object.uuid = this.uuid;
            // return floorPlan;
        }
        let omg = JSON.stringify(object);        
        

        return omg;
    }
     
    public saveAsTemplate() {
        let floorPlan = this.serializer.serialize(this.floors);
        let abroll = this.getabroll();
        let object = JSON.parse(floorPlan);
        let allwalls = FloorPlan.Instance.getWallNodeSeq().getWalls();
        let visibleAngleLabelCount = 0;
        for (let wall of allwalls) {
            if (wall.anglelabel.visible) {
                visibleAngleLabelCount++;
            }
        }
        if(visibleAngleLabelCount >= 1) {
        object.kantungen = visibleAngleLabelCount;
        }
        object.abroll = abroll;
        let omg = JSON.stringify(object);        
        return omg;
    }



    public load(planText: string) {
        let plan: FloorPlanSerializable = JSON.parse(planText)
        this.reset();
        
        let floorData = plan.floors[0];
        let floor = new Floor(floorData);
        
        this.floors.push(floor);
        
        if(this.floors.length > 1) {
             this.floors.splice(0, 1);
        }  
        
        this.floors[0].getWallNodeSequence().setId(plan.wallNodeId);
        this.addChild(this.floors[this.currentFloor])
        this.redrawWalls();
        

    }

    // removes current floor
    public removeFloor() {
        if (this.floors.length < 2) {
            showNotification({
                title:"Plan removal not permitted",
                message:"Tried Action is not permitted .",
                color:'red'
            })
            return;
        }
        let oldCurrentFloor = this.currentFloor;
        this.changeFloor(-1);
        this.floors[oldCurrentFloor].reset();
        this.floors.splice(oldCurrentFloor, 1);
        this.changeFloor(1);
    }

    // cleans up everything. prepare for new load. TODO Feature multiple floors
    public reset() {
        // remove furniture
        for (let floor of this.floors) {
            floor.reset();
        }

        // remove all floors
        this.floors = [];
        this.currentFloor = 0;

    }

    public justclear() {
        this.reset();
        this.floors.push(new Floor());
        
        this.addChild(this.floors[0]);
    }



    public redrawWalls() {
        this.floors[this.currentFloor].redrawWalls();
    }

    public wewantthoseWalls() {
        return this.floors[this.currentFloor].wewantthoseWalls();
    }
    
    public removeWallNode(nodeId: number) {

        this.floors[this.currentFloor].removeWallNode(nodeId)
    }

    public removeWall(wall: Wall) {
        this.floors[this.currentFloor].removeWall(wall);
    }

    public addNodeToWall(wall: Wall, coords: Point) {
        return this.floors[this.currentFloor].addNodeToWall(wall, coords);
    }
    public addNode(leftId: number, rightId: number) {
        return this.floors[this.currentFloor].addNode(leftId, rightId);
    }

    public getWallNodeSeq() {
        if( this.floors.length === 0 ) {
        this.floors.push(new Floor());
        // this.addChild(this.floors[0]);
        
        	}
        return this.floors[this.currentFloor].getWallNodeSequence()
    }

}

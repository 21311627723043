import { Container, Graphics } from "pixi.js";
import { Point } from "../../../../helpers/Point";
import { LabelAxis, } from "../../constants";
import { Handle, HandleType } from "./Handle";
import { Label } from "./Label";

// handles moving, resizing and rotating of objects.
// can only work if its state is active.
export class TransformLayer extends Container {
    private points: Point[];
    private handles: Handle[];
    private labels: Label[];
    private border: Graphics;
    private borderOffset: number;
    public static dragging: boolean;
    private static instance: TransformLayer;

    // private dragging:boolean;
    // private dragStartCoord:Point;
    private constructor() {
        super();
        this.points = [];
        this.handles = [];
        this.labels = [];
        this.visible = false;
        this.border = new Graphics();
        this.borderOffset = 2;
        // this.dragging = false;


        this.addChild(this.border);

        // handles
        this.addHandle(HandleType.Rotate);
        this.addHandle(HandleType.Horizontal);
        this.addHandle(HandleType.HorizontalVertical);
        this.addHandle(HandleType.Vertical);
        this.addHandle(HandleType.Move);

        for (let i = 0; i < 7; i++) {
            this.points[i] = {
                x:0,
                y:0
            }
        }

        this.addLabel(LabelAxis.Horizontal);
        this.addLabel(LabelAxis.Vertical);

    }


    public static get Instance() {
        return this.instance || (this.instance = new this()); // TODO obfuscate
    }



    private addHandle(type: HandleType) {
        let handle = new Handle({ type: type, pos: {x:0, y:0} });
        this.border.addChild(handle);
        this.handles.push(handle);
    }

    private addLabel(axis: LabelAxis) {
        this.labels[axis] = new Label();
        this.border.addChild(this.labels[axis]);
    }


    public deselect() {
        this.visible = false;
        this.interactive = false;
    }

}

import { Container } from "pixi.js";
import { euclideanDistance } from "../../../helpers/EuclideanDistance";
import { Point } from "../../../helpers/Point";
import { getCorrespondingY } from "../../../helpers/Slope";
import { METER } from "../constants";
import { FloorSerializable } from "../persistence/FloorSerializable";
import { Wall } from "./Walls/Wall";
import { WallNodeSequence } from "./Walls/WallNodeSequence";

export class Floor extends Container {

    public wallNodeSequence: WallNodeSequence;
    constructor(floorData?: FloorSerializable, previousFloor?: Floor) {
        super();

        this.wallNodeSequence = new WallNodeSequence();
        this.addChild(this.wallNodeSequence);
        this.wallNodeSequence.zIndex = 1002;
        this.sortableChildren = true;
        if (floorData) {
            let nodeLinks = new Map<number, number[]>(floorData.wallNodeLinks)

            this.wallNodeSequence.load(floorData.wallNodes);
            this.wallNodeSequence.loadwalls(nodeLinks);
            return;
        }

        if (previousFloor) {
            let nodeCloneMap = new Map<number, number>();
            // first iteration, map previous node ids to new node ids as we're simply cloning them
            for (let wall of previousFloor.getExteriorWalls()) {
                [wall.leftNode, wall.rightNode].map((node) => {
                    let oldId = node.getId();
                    if (!nodeCloneMap.has(oldId)) {
                        nodeCloneMap.set(oldId, this.wallNodeSequence.getNewNodeId());
                        this.addNode(node.x, node.y, nodeCloneMap.get(oldId));
                    }
                    return node; // Add this line to fix the issue
                });
            }

            // now copy walls with respect to the node mapping
            previousFloor.getExteriorWalls().forEach(wall => {
                let newLeftId = nodeCloneMap.get(wall.leftNode.getId())
                let newRightId = nodeCloneMap.get(wall.rightNode.getId())

                let newWall = this.wallNodeSequence.addWall(newLeftId, newRightId, false);
                newWall.setIsExterior(true);
            })
        }
    }

    public setfirstanglelabel() {
        let wall = this.wallNodeSequence.getWalls();
        if (wall.length > 0) {
            wall[0].anglelabel.visible = false;
        }
        // wall.anglelabel.visible = false;
    }



    public setLabelVisibility(value = true) {
        for (let wall of this.wallNodeSequence.getWalls()) {
            wall.label.visible = value;
            wall.anglelabel.visible = value;
        }
    }

    private getExteriorWalls() {
        return this.wallNodeSequence.getExteriorWalls();
    }

    public reset() {
        this.wallNodeSequence.reset();
    }
    public getWallNodeSequence() {
        return this.wallNodeSequence
    }

    public clearScreen() {
        for (let child of this.children) {
            child.visible = false;
        }
    }

    public serialize(): FloorSerializable {
        let plan = new FloorSerializable();
        let wallNodes = this.wallNodeSequence.getWallNodes();
        for (let node of wallNodes.values()) {
            plan.wallNodes.push(node.serialize());
        }
        // wall node links
        plan.wallNodeLinks = Array.from(this.wallNodeSequence.getWallNodeLinks().entries());
        return plan;
    }



    public redrawWalls() {
        this.wallNodeSequence.drawWalls();
    }

    public wewantthoseWalls() {
        return this.wallNodeSequence.getWalls();
    }

    public removeWallNode(nodeId: number) {

        if (this.wallNodeSequence.contains(nodeId)) {
            this.wallNodeSequence.remove(nodeId);
        }

    }

    public removeWall(wall: Wall) {
        let leftNode = wall.leftNode.getId();
        let rightNode = wall.rightNode.getId();

        if (this.wallNodeSequence.contains(leftNode)) {
            this.wallNodeSequence.removeWall(leftNode, rightNode);
        }
    }

    public addNode(x: number, y: number, id?: number) {
        return this.wallNodeSequence.addNode(x, y, id, false);

    }

    public addNodeToWall(wall: Wall, coords: Point) {
        let leftNode = wall.leftNode.getId();
        let rightNode = wall.rightNode.getId();
        // ecuatia dreptei, obtine y echivalent lui x
        if (wall.angle !== 90) {
            coords.y = getCorrespondingY(coords.x, wall.leftNode.position, wall.rightNode.position)
        }

        // prevent misclicks
        if (Math.abs(euclideanDistance(coords.x, wall.leftNode.x, coords.y, wall.leftNode.y)) < 0.3 * METER) {
            return;
        }
        if (Math.abs(euclideanDistance(coords.x, wall.rightNode.x, coords.y, wall.rightNode.y)) < 0.3 * METER) {
            return;
        }
        
        // delete wall between left and right node
        this.removeWall(wall);
        // add node and connect walls to it


        let newNode = this.wallNodeSequence.addNode(coords.x, coords.y);
        let newNodeId = newNode.getId()
        this.wallNodeSequence.addWall(leftNode, newNodeId, false);
        this.wallNodeSequence.addWall(newNodeId, rightNode, false);

        return newNode;
    }



}
import { useEffect, useRef, useState } from 'react';
import { Modal, Button, useMantineTheme, Image, createStyles, Stack, MantineProvider } from '@mantine/core';
import { Database, Plus, RotateClockwise } from 'tabler-icons-react';
import { LoadAction } from '../editor/editor/actions/LoadAction';
import ArcadaLogo from '../res/logo.png'
import { FloorPlan } from '../editor/editor/objects/FloorPlan';
import { showNotification } from '@mantine/notifications';
export function WelcomeModal() {
  const [opened, setOpened] = useState(false);
  const fileRef = useRef<HTMLInputElement>();
  const image = <Image src={ArcadaLogo} />
  const useStyles = createStyles(() => ({
    padded: {
      padding: '4px'
    }

  }));


  const loadFromDisk = async (e: any) => {

    let resultText = await e.target.files.item(0).text();

    if (resultText) {
      let action = new LoadAction(resultText);
      action.execute();
      setOpened(false)
    }

  };

  const theme = useMantineTheme();
  const { classes } = useStyles();
  useEffect(() => {
    setOpened(true);
  }, [])

  const notification = {
    title: "Willkommen im neuen heinl-bauelemte Zeichenprogramm! 🎉",
    message: "⚒️ Benutze die Werkzeuge auf der linken Seite um dein Kantteil zu zeichnen. Für detailiertere Hilfe klicke auf das Fragezeichen in der Werkzeugleiste.",
    color: "orange",
    autoClose: true,
  }
  return (
    <>
    <MantineProvider theme={{ colorScheme: 'dark' }}>
      <Modal className={classes.padded}
        closeOnClickOutside={false}
        closeOnEscape={false}
        opened={opened}
        withCloseButton={false}
        overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
        overlayOpacity={0.55}
        centered
        onClose={() => {
          setOpened(false)
          showNotification(notification)
        }}

      >
        <Stack spacing="xs">
          {image}
          <Button onClick={() => {
            setOpened(false); showNotification(notification)
          }} leftIcon={<Plus /> } variant="filled">
            Neues Kantteil
          </Button>
          <input
            ref={fileRef}
            onChange={loadFromDisk}
            multiple={false}
            type="file"
            hidden
          />
          <Button onClick={() => {
            fileRef.current.click()
          }} leftIcon={<Database />} variant="filled">
            Vorlage laden...
          </Button>
          <Button
            onClick={() => {
              if (localStorage.getItem('autosave')) {
                FloorPlan.Instance.load(localStorage.getItem('autosave'));
                setOpened(false);
              }
            }}
            leftIcon={<RotateClockwise />}
            variant="filled"
            disabled={!localStorage.getItem('autosave')}
          >
            Letztes Kantteil
        </Button>
        </Stack>

      </Modal>
      </MantineProvider>
    </>
  );
}
import { useEffect } from 'react';
import './App.css';
import { PageLayout } from './ui/Layout/PageLayout';
import { NotificationsProvider } from '@mantine/notifications';
import { BrowserRouter as Router } from 'react-router-dom';
import useLiveChatWidget from './hooks/useLiveChatWidget'; // Adjust the import path as necessary




function App() {
  useLiveChatWidget();


  useEffect(() => {
  },[])
  return (
    <>
        <Router>
          <NotificationsProvider>
          <PageLayout />
          </NotificationsProvider>
        </Router>
    </>
  );
}
export default App;

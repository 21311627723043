import { Container, Loader, Sprite, Texture } from "pixi.js";
import { showNotification } from "@mantine/notifications";
import { INodeSerializable } from "../../persistence/INodeSerializable";
import { Wall } from "./Wall";
import { WallNode } from "./WallNode";
import UpArcadaLogo from '../../../../res/up_rounded.svg';
import LeftArcadaLogo from '../../../../res/left_rounded.svg';
import RightArcadaLogo from '../../../../res/right_rounded.svg';
import DownArcadaLogo from '../../../../res/down_rounded.svg';
import ArcadaLogoIcn from '../../../../res/rounded1.svg';
import FirstArcadaLogoIcn from '../../../../res/firstRounded1.svg';
import { useStore } from "../../../../stores/EditorStore";

export class WallNodeSequence extends Container {
    private wallNodes: Map<number, WallNode>;
    private wallNodeLinks: Map<number, number[]>;
    private svgWalls: Map<number, Sprite>;
    private firstNode: WallNode;
    private secFirstNode: WallNode;
    private pastCurrentNode: WallNode;
    private currentNode: WallNode;
    private currentButtons: Container;
    private walls: Wall[];
    private static wallNodeId: number = 0;
    private loader: Loader;

    constructor() {
        super();
        this.sortableChildren = true;
        this.walls = [];
        this.wallNodes = new Map<number, WallNode>();
        this.wallNodeLinks = new Map<number, number[]>();
        this.svgWalls = new Map<number, Sprite>();

        this.loader = new Loader();
        this.loader.add('FirstArcadaLogoIcn', FirstArcadaLogoIcn);
        this.loader.add('ArcadaLogoIcn', ArcadaLogoIcn);
        this.loader.add('UpArcadaLogo', UpArcadaLogo);
        this.loader.add('LeftArcadaLogo', LeftArcadaLogo);
        this.loader.add('RightArcadaLogo', RightArcadaLogo);
        this.loader.add('DownArcadaLogo', DownArcadaLogo);

        // Load the textures
        this.loader.load(() => {
            this.drawWalls();
        });

        this.on("mousemove", this.drawWalls);

        useStore.subscribe((isWallColorSwitched) => {
            this.drawWalls();
        }, state => state.isWallColorSwitched)

    }

    private reassignNodeIdsAndLinks(newFirstNodeId: number) {
        const newWallNodes = new Map<number, WallNode>();
        const newWallNodeLinks = new Map<number, number[]>();
    
        this.firstNode.setId(newFirstNodeId);
        newWallNodes.set(newFirstNodeId, this.firstNode);
        newWallNodeLinks.set(newFirstNodeId, []);
    
        let maxId = newFirstNodeId;
        this.wallNodes.forEach((node, oldId) => {
            if (node !== this.firstNode) {
                const newId = oldId + 1;
                node.setId(newId);
                newWallNodes.set(newId, node);
    
                let updatedLinks = this.wallNodeLinks.get(oldId)?.map(id => id === newFirstNodeId ? 1 : id + 1) || [];
                if (newId === 2) {
                    updatedLinks = updatedLinks.filter(id => id !== 1)
                }
                newWallNodeLinks.set(newId, updatedLinks);
    
                if (newId > maxId) {
                    maxId = newId;
                }
            }
        });
        
        // Explicitly link NodeId 1 with NodeId 2 if not already linked
        if (!newWallNodeLinks.get(newFirstNodeId)?.includes(newFirstNodeId + 1)) {
            newWallNodeLinks.get(newFirstNodeId)?.push(newFirstNodeId + 1);
        }
        if (!newWallNodeLinks.get(newFirstNodeId + 1)?.includes(newFirstNodeId)) {
            newWallNodeLinks.get(newFirstNodeId + 1)?.push(newFirstNodeId);
        }
        newWallNodeLinks.set(2, newWallNodeLinks.get(2).filter(id => id !== 1))
    
        this.wallNodes = newWallNodes;
        this.wallNodeLinks = newWallNodeLinks;
        WallNodeSequence.wallNodeId = maxId;
    }

    public createFirstButton() {
        const texture = Texture.from(FirstArcadaLogoIcn);
        var firstIcon = new Sprite(texture);

        // Adjust the position if necessary
        firstIcon.x = this.firstNode.x;
        firstIcon.y = this.firstNode.y - 40;

        // Optionally, set the size of the icon
        firstIcon.width = 100; // Adjust width as needed
        firstIcon.height = 20; // Adjust height as needed

        this.addChild(firstIcon);

        firstIcon.interactive = true;
        firstIcon.buttonMode = true;
        firstIcon.on("pointerdown", ()=>{
            const xDiff = this.secFirstNode.x - this.firstNode.x;
            const yDiff = this.secFirstNode.y - this.firstNode.y;
            var nodeOffsetX = 80;
            
            if (Math.abs(xDiff) > 0 || Math.abs(yDiff) > 0) {
                if (Math.abs(xDiff) > Math.abs(yDiff)) {
                    // More horizontal movement
                    if (xDiff > 0) { // Drawing to the right
                    } else { // Drawing to the left
                        nodeOffsetX = -80;
                    }
                } else {
                    // More vertical movement
                    if (xDiff > 0) { // Drawing downwards in screen-space
                    } else { // Drawing upwards in screen-space
                        nodeOffsetX = -80;
                    }
                }
            }
            const nodeOffsetY = 0;
            const nodeRadians = this.firstNode.angle * (Math.PI / 180);
            const nodeRotatedX = this.firstNode.x + nodeOffsetX * Math.cos(nodeRadians) - nodeOffsetY * Math.sin(nodeRadians);
            const nodeRotatedY = this.firstNode.y + nodeOffsetX * Math.sin(nodeRadians) + nodeOffsetY * Math.cos(nodeRadians);
            const newNodeX = nodeRotatedX; 
            const newNodeY = nodeRotatedY; 
            // const newNodeId = this.getNewNodeId();
            const newNodeId = 1;
            const newNode = new WallNode(newNodeX, newNodeY, newNodeId, true);
            this.firstNode = newNode; // Set the new node as the first node
            this.reassignNodeIdsAndLinks(newNodeId);
            this.wallNodes.set(newNodeId, newNode);
            this.wallNodeLinks.set(newNodeId, []);
            this.addChild(newNode);
            console.log("newnodeid: ", newNode.getId(), "firstnodeid: ", this.firstNode.getId(), "firstnode x + y: ", this.wallNodeLinks)
            this.addWall( newNode.getId(), 2,true);
            if (!firstIcon.destroyed) {
                firstIcon.destroy(true);
            }
        });
    }

    public createButton() {
        const texture = Texture.from(ArcadaLogoIcn);
        var icon = new Sprite(texture);

        // Adjust the position if necessary
        icon.x = this.currentNode.x;
        icon.y = this.currentNode.y - 40;

        // Optionally, set the size of the icon
        icon.width = 100; // Adjust width as needed
        icon.height = 20; // Adjust height as needed

        this.addChild(icon);

        icon.interactive = true;
        icon.buttonMode = true;
        icon.on("pointerdown", ()=>{
            const xDiff = this.currentNode.x - this.pastCurrentNode.x;
            const yDiff = this.currentNode.y - this.pastCurrentNode.y;
            var nodeOffsetX = -80;
            
            if (Math.abs(xDiff) > 0 || Math.abs(yDiff) > 0) {
                if (Math.abs(xDiff) > Math.abs(yDiff)) {
                    // More horizontal movement
                    if (xDiff > 0) { // Drawing to the right
                        showNotification({
                            title: 'Not permitted',
                            color: 'red',
                            message: 'sec right'
                        })
                    } else { // Drawing to the left
                        nodeOffsetX = 80;
                        showNotification({
                            title: 'Not permitted',
                            color: 'red',
                            message: 'sec left'
                        })
                    }
                } else {
                    // More vertical movement
                    if (xDiff > 0) { // Drawing downwards in screen-space
                        showNotification({
                            title: 'Not permitted',
                            color: 'red',
                            message: 'sec down'
                        })
                    } else { // Drawing upwards in screen-space
                        nodeOffsetX = 80;
                        showNotification({
                            title: 'Not permitted',
                            color: 'red',
                            message: 'sec upwards'
                        })
                    }
                }
            }
            const nodeOffsetY = 0;
            const nodeRadians = this.currentNode.angle * (Math.PI / 180);
            const nodeRotatedX = this.currentNode.x + nodeOffsetX * Math.cos(nodeRadians) - nodeOffsetY * Math.sin(nodeRadians);
            const nodeRotatedY = this.currentNode.y + nodeOffsetX * Math.sin(nodeRadians) + nodeOffsetY * Math.cos(nodeRadians);
            const newNodeX = nodeRotatedX; 
            const newNodeY = nodeRotatedY; 
            const newNodeId = this.getNewNodeId();
            const newNode = new WallNode(newNodeX, newNodeY, newNodeId, true);
            this.wallNodes.set(newNodeId, newNode);
            this.wallNodeLinks.set(newNodeId, []);
            this.addChild(newNode);
            this.addWall(this.currentNode.getId(), newNode.getId(), true);
            this.pastCurrentNode = this.currentNode;
            this.currentNode = newNode;
            if (!this.currentButtons.destroyed){
                this.currentButtons.destroy(true);
            }
            // AddWallManager.Instance.changePreview(newNode)
        });
        this.currentButtons = icon;
    }

    public setId(id: number) {
        WallNodeSequence.wallNodeId = id;
    }

    public getExteriorWalls(): Wall[] {
        return this.walls.filter(wall => wall.isExteriorWall);
    }

    public getWallNodeId() {
        return WallNodeSequence.wallNodeId;
    }

    public contains(id: number) {
        return this.wallNodes.has(id);
    }

    public getWalls() {
        return this.walls;
    }

    public deleteSvg(id: any) {
        if(this.svgWalls.has(id)) {                
            this.svgWalls.get(id).destroy(true);
            this.svgWalls.delete(id);
        }
    }

    public getWallNodes() {
        return this.wallNodes;
    }

    public getWallNodeLinks() {
        return this.wallNodeLinks;
    }

    public loadwalls(nodeLinks: Map<number, number[]>) {
        for (let [src, dests] of nodeLinks) {
            for (const dest of dests) {
                this.addWall(src, dest, false);
            }
        }
    }

    public load(nodes: INodeSerializable[]) {
        for (let node of nodes) {
            this.addNode(node.x, node.y, node.id, node.isAllowed);
        }
    }

    public reset() {
        for (let key of this.wallNodes.keys()) {
            this.wallNodes.get(key).destroy(true);
        }
        this.wallNodes.clear();

        for (let wall of this.walls) {
            wall.destroy(true);
        }
        this.walls = [];

        this.wallNodeLinks.clear();
        WallNodeSequence.wallNodeId = 0;
        if (this.currentButtons && !this.currentButtons.destroyed) {
            if (typeof this.currentButtons.destroy === 'function') {
                this.currentButtons.destroy(true);
            }
        }
        for (let key of this.svgWalls.keys()) {
            this.svgWalls.get(key).destroy(true);
        }
    }

    public remove(id: number) {
        let isolated = true;
        if (this.wallNodeLinks.get(id).length > 0) {
            isolated = false;
        } else {
            for (let src of this.wallNodeLinks.keys()) {
                for (const dest of this.wallNodeLinks.get(src)) {
                    if (dest === id) {
                        isolated = false;
                    }
                }
            }
        }

        if (isolated) {
            this.wallNodes.get(id)!.destroy(true);
            this.wallNodes.delete(id);
            if(!this.currentButtons.destroyed) {
                this.currentButtons.destroy(true);
            }
        } else {
            showNotification({
                title: 'Not permitted',
                color: 'red',
                message: 'Cannot delete node with walls attached. Please remove walls first.',
            });
        }
    }

    public getNewNodeId() {
        WallNodeSequence.wallNodeId += 1;
        return WallNodeSequence.wallNodeId;
    }

    public addNode(x: number, y: number, id?: number, isAllowed?: boolean) {
        let nodeId;
        if (id) {
            nodeId = id;
        } else {
            nodeId = this.getNewNodeId();
        }
        var isAllowedLoc = false
        if (isAllowed) {
            isAllowedLoc = true
        }
        const node = new WallNode(x, y, nodeId, isAllowedLoc);
        this.wallNodes.set(nodeId, node);
        this.wallNodeLinks.set(nodeId, []);
        this.addChild(node);
        if (nodeId === 1) {
            this.firstNode = node;
            this.createFirstButton();
        } else if (nodeId === 2) {
            this.secFirstNode = node;
        }
        // if (nodeId <= 1 ){
        //     this.firstNode = node;
        // }
        else {
            if (this.currentNode != null && this.currentButtons != null && !this.currentButtons.destroyed){
                this.pastCurrentNode = this.currentNode;
                this.currentNode = node;
                this.currentButtons.position.set(x, y - 40);
            } else {
                this.pastCurrentNode = this.firstNode;
                this.currentNode = node;
                this.createButton();
            }
        }

        return node;
    }

    public addWall(leftNodeId: number, rightNodeId: number, isAllowed: boolean) {
        if (leftNodeId === rightNodeId) {
            return;
        }
        if (leftNodeId > rightNodeId) {
            let aux = leftNodeId;
            leftNodeId = rightNodeId;
            rightNodeId = aux;
        }

        if (this.wallNodeLinks.has(leftNodeId) && this.wallNodeLinks.get(leftNodeId)?.includes(rightNodeId)) {
            return;
        }
        this.wallNodeLinks.get(leftNodeId).push(rightNodeId);
        const leftNode = this.wallNodes.get(leftNodeId);
        const rightNode = this.wallNodes.get(rightNodeId);
        let wall = new Wall(leftNode, rightNode, isAllowed);
        this.walls.push(wall);
        this.addChild(wall);
        this.drawWalls();
        return wall;
    }

    public removeWall(leftNode: number, rightNode: number) {
        const index = this.wallNodeLinks.get(leftNode).indexOf(rightNode);

        if (index !== -1) {
            this.wallNodeLinks.get(leftNode).splice(index, 1);
            this.drawWalls();
        }
        let toBeRemoved = -1;
        for (let i = 0; i < this.walls.length; i++) {
            let wall = this.walls[i];
            if (wall.leftNode.getId() === leftNode && wall.rightNode.getId() === rightNode) {
                toBeRemoved = i;
                break;
            }
        }
        if (toBeRemoved !== -1) {
            this.removeChild(this.walls[toBeRemoved]);
            this.walls.splice(toBeRemoved, 1);
        }
    }

    public getWall(object: { leftNode?: number; rightNode?: number }): Wall | undefined {
        const allWalls = this.walls;

        if (object.rightNode) {
            const wall = allWalls.find(wall => wall.rightNode.getId() === object.rightNode);

            if (wall) {
                return wall;
            }
        }

        if (object.leftNode) {
            const wall = allWalls.find(wall => wall.leftNode.getId() === object.leftNode);

            if (wall) {
                return wall;
            }
        }

        return undefined;
    }

    public drawWalls() {
        const isWallColorSwitched = useStore.getState().isWallColorSwitched;
        this.walls.forEach(wall => {
            wall.drawLine();            
            let nnode
            if (wall.leftNode.getId() === 1 && (wall.isAllowed || wall.leftNode.isAllowed || wall.rightNode.isAllowed)){
                nnode = this.walls.find(w=>w.leftNode.getId() === wall.rightNode.getId())
                if (!nnode) {
                    nnode = wall
                }
            } else {
                nnode = wall
            }                  
            if(Math.round(nnode.angle2) < 1 && (wall.isAllowed || wall.leftNode.isAllowed || wall.rightNode.isAllowed)) {
                if(this.svgWalls.has(wall.leftNode.getId())) {
                    this.svgWalls.get(wall.leftNode.getId()).destroy(true);
                    this.svgWalls.delete(wall.leftNode.getId());
                }
                var texture = Texture.from(UpArcadaLogo);
                var offsetX = 0;
                var offsetY = -4;
                var leftNode = wall.leftNode
                var rightNode = wall.rightNode
                if (wall.leftNode.isAllowed){
                    leftNode = wall.rightNode
                    rightNode = wall.leftNode
                }
                
                const xDiff = rightNode.position.x - leftNode.position.x;
                const yDiff = rightNode.position.y - leftNode.position.y;
                if (Math.abs(xDiff) > 0 || Math.abs(yDiff) > 0) {
                    if (Math.abs(xDiff) > Math.abs(yDiff)) {
                        // More horizontal movement
                        if (xDiff > 0) { // Drawing to the right
                            // Paint bottom side
                            texture = Texture.from(RightArcadaLogo);
                            offsetX = -25
                            if (isWallColorSwitched || wall.switchColor || wall.leftNode.isAllowed) {
                            } else {
                                offsetY = -22
                            }
                            if (wall.switchColor || wall.leftNode.isAllowed){
                                if(isWallColorSwitched){offsetY = -22}else{offsetY = -4}
                            }
                        } else { // Drawing to the left
                            // Paint top side
                            texture = Texture.from(LeftArcadaLogo);
                            if (isWallColorSwitched || wall.switchColor || wall.leftNode.isAllowed) {
                                offsetY = -22
                            } else {}
                            if (wall.switchColor || wall.leftNode.isAllowed){
                                if(isWallColorSwitched){offsetY = -4}else{offsetY = -22}
                            }
                        }
                    } else {
                        // More vertical movement
                        if (xDiff > 0) { // Drawing downwards in screen-space
                            // Paint right side
                            texture = Texture.from(RightArcadaLogo);
                            offsetX = -25
                        } else { // Drawing upwards in screen-space
                            // Paint left side
                            texture = Texture.from(LeftArcadaLogo);
                        }
                        if (yDiff > 0) { // Drawing downwards in screen-space
                            // Paint right side
                            if (isWallColorSwitched || wall.switchColor || wall.leftNode.isAllowed) {} else {offsetY = -22}
                            if (wall.switchColor || wall.leftNode.isAllowed){
                                if(isWallColorSwitched){offsetY = -22}else{offsetY = -4}
                            }
                        } else { // Drawing upwards in screen-space
                            // Paint left side
                            if (isWallColorSwitched || wall.switchColor || wall.leftNode.isAllowed) {offsetY = -22} else {}
                            if (wall.switchColor || wall.leftNode.isAllowed){
                                if(isWallColorSwitched){offsetY = -4}else{offsetY = -22}
                            }
                        }
                    }
                }
                let svg = new Sprite(texture);
                const radians = wall.angle * (Math.PI / 180);
                const rotatedX = leftNode.x + offsetX * Math.cos(radians) - offsetY * Math.sin(radians);
                const rotatedY = leftNode.y + offsetX * Math.sin(radians) + offsetY * Math.cos(radians);

                svg.x =  rotatedX;
                svg.y = rotatedY;
                svg.width = 25;
                svg.height = 26;
                svg.angle = wall.angle;
                
                var oldleftNodeoX = leftNode.x
                var oldleftNodeoY = leftNode.y
                const leftNodeoffsetX = 0;
                var leftNodeoffsetY = 18;
                if (offsetY === -22) leftNodeoffsetY = -18;
                const leftNoderadians = wall.angle * (Math.PI / 180);
                const leftNoderotatedX = leftNode.x + leftNodeoffsetX * Math.cos(leftNoderadians) - leftNodeoffsetY * Math.sin(leftNoderadians);
                const leftNoderotatedY = leftNode.y + leftNodeoffsetX * Math.sin(leftNoderadians) + leftNodeoffsetY * Math.cos(leftNoderadians);
                leftNode.position.set(leftNoderotatedX, leftNoderotatedY)

                var oldrightNodeoX = rightNode.x
                var oldrightNodeoY = rightNode.y
                const rightNodeoffsetX = 0;
                var rightNodeoffsetY = 10;
                if (offsetY === -22) rightNodeoffsetY = -10;
                const rightNoderadians = wall.angle * (Math.PI / 180);
                const rightNoderotatedX = rightNode.x + rightNodeoffsetX * Math.cos(rightNoderadians) - rightNodeoffsetY * Math.sin(rightNoderadians);
                const rightNoderotatedY = rightNode.y + rightNodeoffsetX * Math.sin(rightNoderadians) + rightNodeoffsetY * Math.cos(rightNoderadians);
                rightNode.position.set(rightNoderotatedX, rightNoderotatedY)

                wall.drawLine();
                leftNode.position.set(oldleftNodeoX, oldleftNodeoY)
                rightNode.position.set(oldrightNodeoX, oldrightNodeoY)
                wall.anglelabel.update2(0)
                this.addChild(svg);
                this.svgWalls.set(wall.leftNode.getId(), svg);
            } else if(this.svgWalls.has(wall.leftNode.getId())) {                
                this.svgWalls.get(wall.leftNode.getId()).destroy(true);
                this.svgWalls.delete(wall.leftNode.getId());
            }
        });
    }
}
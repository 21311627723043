import { Floor } from "../objects/Floor";
import { FloorPlanSerializable } from "./FloorPlanSerializable";

export class Serializer {


    public serialize(floors: Floor[]) {
        let floorPlanSerializable = new FloorPlanSerializable();
        for (let floor of floors) {
            let floorSerializable = floor.serialize();
            floorPlanSerializable.floors.push(floorSerializable)

        }
        floorPlanSerializable.wallNodeId = floors[0].getWallNodeSequence().getWallNodeId();
        let resultString = JSON.stringify(floorPlanSerializable)
        return resultString
    }
}
import React from 'react';
import { ReactComponent as CatalogueSVG } from '../../res/folder.svg';

interface CatalogueIconProps {
    width?: number;
    height?: number;
    color?: string;
}

const CatalogueIcon: React.FC<CatalogueIconProps> = ({
    width = 24,
    height = 24,
    color = 'white',
}) => {
    return (
        <CatalogueSVG
            width={width}
            height={height}
            fill={color}
        />
    );
};

export default CatalogueIcon;
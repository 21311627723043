import { ReactNode, useState } from 'react';
import { Dialog, Group, Text } from '@mantine/core';
import { useStore } from '../stores/EditorStore';
import { NavbarLink } from './NavbarLink';
import { ArrowNarrowRight, Click,GitFork,Help, Multiplier2x, Trash, Vector, ZoomIn } from 'tabler-icons-react';
import { Tool } from '../editor/editor/constants';
import { Image } from '@mantine/core';
import helpAddWall from '../res/add-wall.gif'
import helpDelete from '../res/delete.gif'
import helpEditWall from '../res/edit-walls.gif'
import messure from '../res/draw.gif'
interface IHelpBody {
  title: string,
  body: ReactNode
}




export function HelpDialog() {
  const [opened, setOpened] = useState(false);

  const { activeTool } = useStore();
  let helpBody: IHelpBody[] = [];

  helpBody[Tool.View] = {
    title: "Ansehen Modus",
    body: <>
      <Group>
        <Click /> <p>Rechtsklick und ziehen um den Plan zu verschieben </p>
      </Group>
      <Group>
        <ZoomIn /> <p>Um raus und rein zu zoomen Mausrad verwenden</p>
      </Group>
    </>
  }  

  helpBody[Tool.Remove] = {
    title: "Löschen Modus",
    body: <>
      <Image src={helpDelete}></Image>
      <Group spacing="xs">
        <Click /> <ArrowNarrowRight /> <Trash /> <p> Auf die Linie klicken um es zu löschen</p>
      </Group>
      <Group noWrap={true}>
        <Vector /> <p>Ecken werden nur gelöscht wenn die Linien zuvor gelöscht wurden</p>
      </Group>
    </>
  }
  helpBody[Tool.Edit] = {
    title: "Bearbeiten Modus",
    body: <>
      <Image src={helpEditWall}></Image>
      <Group noWrap={true}>
        <Vector /> <p>Klicken und ziehen um Linien zu ändern</p>
      </Group>
    </>
  }
  helpBody[Tool.WallAdd] = {
    title: "Zeichnen",
    body: <>
      <Image src={helpAddWall}></Image>
      <Group noWrap={true}>
        <Click /> <p>Klicken um neue Linien zu zeichnen</p>
      </Group>
      <Group noWrap={true}>
        <Multiplier2x /> <p>Doppelklick um das Zeichnen vorübergehend zu beenden</p>
      </Group>
      <Group noWrap={true}>
        <GitFork /> <p>Auf einer existierenden Linie klicken um dort anzuhängen</p>
      </Group>
    </>
  }
    helpBody[Tool.Measure] = {
    title: "Messen",
    body: <>
      <Image src={messure}></Image>
      <Group noWrap={true}>
        <Click /> <p>Klicken und Ziehen um zu Messen</p>
      </Group>
    </>
  }
  return (
    <>
      <Group position="center">
        <NavbarLink onClick={() => setOpened((o) => !o)} icon={Help} label="Hilfe" />
      </Group>

      <Dialog
        opened={opened}
        withCloseButton
        onClose={() => setOpened(false)}
        size="lg"
        radius="md"
        position={{ top: 20, right: 20 }}
      >
        <Text size="sm" style={{ marginBottom: 10 }} weight={500}>
          <b>{helpBody[activeTool].title}</b>
          {helpBody[activeTool].body}
        </Text>
      </Dialog>
    </>
  );
}
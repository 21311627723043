import { useEffect } from 'react';

const useLiveChatWidget = () => {
  useEffect(() => {
    const BASE_URL = "https://mailing.heinl-bauelemente.de";
    const script = document.createElement('script');
    script.src = `${BASE_URL}/packs/js/sdk.js`;
    script.defer = true;
    script.async = true;

    script.onload = () => {
      // @ts-ignore
      window.chatwootSDK.run({
        websiteToken: 'PnoxVWcyApssdJhU4LCPo',
        baseUrl: BASE_URL
      });
    };

    document.getElementsByTagName('script')[0].parentNode.insertBefore(script, document.getElementsByTagName('script')[0]);

    // Cleanup function to remove the script tag when the component unmounts
    return () => {
      document.getElementsByTagName('script')[0].parentNode.removeChild(script);
    };
  }, []);
};

export default useLiveChatWidget;
import { Graphics, InteractionEvent, Sprite, Texture } from "pixi.js";
import { euclideanDistance } from "../../../../helpers/EuclideanDistance";
import { Point } from "../../../../helpers/Point";
import { viewportX, viewportY } from "../../../../helpers/ViewportCoordinates";

import { useStore } from "../../../../stores/EditorStore";
import { AddNodeAction } from "../../actions/AddNodeAction";
import { DeleteWallAction } from "../../actions/DeleteWallAction";
import { INTERIOR_WALL_THICKNESS, METER, Tool, WALL_THICKNESS } from "../../constants";
import { Label } from "../TransformControls/Label";
import { WallNode } from "./WallNode";
import { FloorPlan } from "../FloorPlan";
import { ActionHistory } from "../../actions/ActionHistory";
import { showNotification } from "@mantine/notifications";



export class Wall extends Graphics {

    leftNode: WallNode;
    rightNode: WallNode;
    length: number;
    label: Label;
    anglelabel: Label;




    x1: number;
    x2: number;
    y1: number;
    y2: number;
    thickness: number;
    isExteriorWall: boolean;
    distance: number;
    
    dragging: boolean;
    mouseStartPoint: Point;
    startLeftNode: Point;
    startRightNode: Point;
    angle2: number;
    previousPosition: { x: number; y: number; };
    isAllowed: boolean;
    switchColor: boolean;

    constructor(leftNode: WallNode, rightNode: WallNode, isAllowed: boolean) {
        super();
        this.sortableChildren = true;
        
        this.interactive = true;
        this.leftNode = leftNode;
        this.rightNode = rightNode;
        this.isAllowed = isAllowed;
        this.dragging = false;
        this.switchColor = false;
        this.mouseStartPoint = {x:0, y:0}
        this.startLeftNode = {x:0, y:0}
        this.startRightNode = {x:0, y:0}
        this.setLineCoords();
        this.label = new Label(0, this);
        this.distance = 0;
        
        this.anglelabel = new Label(0, this);
        this.addChild(this.anglelabel)
        this.addChild(this.label)
        this.thickness = INTERIOR_WALL_THICKNESS;
        this.pivot.set(0, INTERIOR_WALL_THICKNESS / 2);
        this.zIndex = 100;
        this.isExteriorWall = false;
        this.previousPosition = { x: 0, y: 0 };
        // this.drawLine();

        this.on("pointerdown", this.onMouseDown)
        this.on("rightdown", this.onRightDown)
        this.on("pointermove", this.onMouseMove)
        this.on("pointerup", this.onMouseUp)
        this.on("pointerupoutside", this.onMouseUp);
    }


    public setIsExterior(value: boolean) {
        this.isExteriorWall = value;
        if (value) {
            this.thickness = WALL_THICKNESS;
        } else {
            this.thickness = INTERIOR_WALL_THICKNESS;
        }
        this.pivot.set(0, this.thickness / 2);
        this.leftNode.setSize(this.thickness);
        this.rightNode.setSize(this.thickness);
        this.drawLine();
    }

    public getIsExterior() {
        return this.isExteriorWall;
    }
    public setLineCoords() {
        
        if (this.leftNode.x === this.rightNode.x) {
            
            if (this.leftNode.y < this.rightNode.y) {
                return [this.leftNode.x, this.leftNode.y, this.rightNode.x, this.rightNode.y]
            } else {
                return [this.rightNode.x, this.rightNode.y, this.leftNode.x, this.leftNode.y]
            }
        } else if (this.leftNode.x < this.rightNode.x) {
            return [this.leftNode.x, this.leftNode.y, this.rightNode.x, this.rightNode.y]
        } else {
            return [this.rightNode.x, this.rightNode.y, this.leftNode.x, this.leftNode.y]
        }
    }

    public getthesenodes (){

    console.error("This function is not implemented yet")
    }

    public calculateLineWidth(): number {
        const dx = this.rightNode.x - this.leftNode.x;
        const dy = this.rightNode.y - this.leftNode.y;
        return Math.sqrt(dx * dx + dy * dy);
    }

    public isPointOnWall(coords): boolean {
        // Calculate the distances from the point to the wall's nodes
        const distToStart = euclideanDistance(coords.x, this.leftNode.x, coords.y, this.leftNode.y);
        const distToEnd = euclideanDistance(coords.x, this.rightNode.x, coords.y, this.rightNode.y);
    
        // If the sum of the distances to the start and end nodes is approximately equal to the wall's length,
        // then the point is on the wall
        const isOnWall = Math.abs(distToStart + distToEnd - this.length) < 0.01 * METER; // 0.01 is a small tolerance value
    
        return isOnWall;
    }

    public lengthlabelgotchanged (newLength: number) {
        // Calculate the current angle in radians
        let dx = this.rightNode.position.x - this.leftNode.position.x;
        let dy = this.rightNode.position.y - this.leftNode.position.y;
        let currentAngle = Math.atan2(dy, dx);
        // Calculate the new coordinates based on the left node's position and the new length
        let newx = this.leftNode.position.x + newLength * Math.cos(currentAngle);
        let newy = this.leftNode.position.y + newLength * Math.sin(currentAngle);

        // Set the new position
        this.rightNode.setPositiontest(newx, newy);
        FloorPlan.Instance.redrawWalls();
        // *do not forget to recalculate the widht
    }



    // public anglelablegotchanged (newAngle: number) {
    //     if (!this.leftNode) return
    //     let searchid = this.leftNode.getId();
    //     let firstline;
    //     let A
    //     let B
    //     let C
    //     if (searchid === 1) {
    //         firstline = FloorPlan.Instance.getWallNodeSeq().getWall({leftNode: 2});
    //         A = firstline.rightNode.position;
    //         B = this.rightNode.position;
    //         C = this.leftNode.position;
    //     } else {
    //         firstline = FloorPlan.Instance.getWallNodeSeq().getWall({rightNode: searchid});
    //         A = firstline.leftNode.position;
    //         B = firstline.rightNode.position;
    //         C = this.rightNode.position;
    //     }
    //     if(this.angle2 === newAngle && !this.isAllowed){
    //         return;
    //     }
    //     let BCx = C.x - B.x;
    //     let BCy = C.y - B.y;
    //     let distanceBC = Math.sqrt(BCx * BCx + BCy * BCy);
    
    //     let ABx = B.x - A.x;
    //     let ABy = B.y - A.y;
    //     let baseAngleDegrees = Math.atan2(ABy, ABx) * (180 / Math.PI);
    
    //     // Subtract the base angle from the new angle instead of adding
    //     let newAngleDegrees = newAngle + 180 + baseAngleDegrees;

    //     let newAngleRadians = newAngleDegrees * (Math.PI / 180);
    
    //     let newCx = B.x + distanceBC * Math.cos(newAngleRadians);
    //     let newCy = B.y + distanceBC * Math.sin(newAngleRadians);
    //     if (searchid === 1) {
    //         this.leftNode.setPositiontest(newCx, newCy);
    //     } else {
    //         this.rightNode.setPositiontest(newCx, newCy);
    //     }     
    //     FloorPlan.Instance.redrawWalls();
    // }


    public anglelablegotchanged(newAngle: number) {
        if (!this.leftNode) return;

        // Store original wall data before making changes
        FloorPlan.Instance.selectedWall = this;
        
        let searchid = this.leftNode.getId();
        let firstline;
        let A, B, C;
        
        if (searchid === 1) {
            firstline = FloorPlan.Instance.getWallNodeSeq().getWall({ leftNode: 2 });
            A = firstline.rightNode.position;
            B = this.rightNode.position;
            C = this.leftNode.position;
        } else {
            firstline = FloorPlan.Instance.getWallNodeSeq().getWall({ rightNode: searchid });
            A = firstline.leftNode.position;
            B = firstline.rightNode.position;
            C = this.rightNode.position;
        }
        
        if (this.angle2 === newAngle && !this.isAllowed) {
            return;
        }
        
        let BCx = C.x - B.x;
        let BCy = C.y - B.y;
        let distanceBC = Math.sqrt(BCx * BCx + BCy * BCy);
        
        let ABx = B.x - A.x;
        let ABy = B.y - A.y;
        let baseAngleDegrees = Math.atan2(ABy, ABx) * (180 / Math.PI);
        
        let newAngleDegrees = newAngle + 180 + baseAngleDegrees;
        let newAngleRadians = newAngleDegrees * (Math.PI / 180);
        
        let newCx = B.x + distanceBC * Math.cos(newAngleRadians);
        let newCy = B.y + distanceBC * Math.sin(newAngleRadians);
        
        if (searchid === 1) {
            this.leftNode.setPositiontest(newCx, newCy);
        } else {
            this.rightNode.setPositiontest(newCx, newCy);
        }
        
        // Redraw only the affected wall
        this.drawLine();
        
        // Recalculate positions for other walls
        FloorPlan.Instance.storeOriginalWallData();
        FloorPlan.Instance.recalculateWallPositions();
    }


    public wewantnewnode (){
        let firstnodeid;

        let firstnode = this.leftNode.getId();
        if(firstnode === undefined){
            return undefined
        }
        if (firstnode === 1) {
            firstnodeid = 3;
        }
        if(firstnode >= 2 || this.rightNode.getId() >= 3){
            // nodecoords.push({x: this.leftNode.x, y: this.leftNode.y})
            firstnodeid = firstnode;
        }
        // * now we get the other node
        let allcoords = [];
        let allwallnodes = FloorPlan.Instance.getWallNodeSeq().getWallNodes();
        if (allwallnodes === undefined) {
            

            return undefined
        }else {

        let coordweneed = [];
        if(allwallnodes.size >= 3 && firstnodeid >= 2){
            // eslint-disable-next-line
        for (let [id ,node] of allwallnodes) {
            allcoords.push({x: node.x, y: node.y})
        }
        if (firstnode === 1){
            coordweneed.push(allcoords[firstnodeid])
            coordweneed.push(allcoords[firstnodeid - 1])
            coordweneed.push(allcoords[firstnodeid - 2])
        } else {
            coordweneed.push(allcoords[firstnodeid - 2])
            coordweneed.push(allcoords[firstnodeid - 1])
            coordweneed.push(allcoords[firstnodeid])
        }
        
        return coordweneed}
        else{
            

        return coordweneed;}
    }

    }


    private newanglefunction () {
        let coordweneed = this.wewantnewnode(); 
        if(coordweneed === undefined){
            return undefined
        }
        if(coordweneed.length < 2){
            return undefined
        }
        let A = coordweneed[0];
        let B = coordweneed[1];
        const Ay = B.y - A.y;
        const Ax = B.x - A.x;
        let angle = Math.atan2(Ay, Ax) * 180 / Math.PI;
        
        let outerAngleDegrees = 180 - angle;
        return  outerAngleDegrees;
    }

    private letsgetingstartedanglefunction() {

        if(this.wewantnewnode()){
        let slicedcoords = this.wewantnewnode(); // Assuming it returns at least three nodes
    
        // Ensure there are enough nodes to calculate the angle
        if (slicedcoords && slicedcoords.length >= 3 && !slicedcoords.includes(undefined)) {
            // Nodes A, B, C
            let A = slicedcoords[0];
            let B = slicedcoords[1];
            let C = slicedcoords[2];
            // Calculate vectors AB and BC
            let ABx = B.x - A.x;
            let ABy = B.y - A.y;
            let BCx = C.x - B.x;
            let BCy = C.y - B.y;
    
            // Calculate the dot product of AB and BC
            let dotProduct = ABx * BCx + ABy * BCy;
    
            // Calculate magnitudes of AB and BC
            let magAB = Math.sqrt(ABx * ABx + ABy * ABy);
            let magBC = Math.sqrt(BCx * BCx + BCy * BCy);
    
            // Calculate the angle in radians
            let angleRadians = Math.acos(dotProduct / (magAB * magBC));
    
            // Convert to degrees
            let angleDegrees = angleRadians * (180 / Math.PI);

            let outerAngleDegrees = 180 - angleDegrees; 
            if (isNaN(outerAngleDegrees) && (this.isAllowed || this.leftNode.isAllowed || this.rightNode.isAllowed)) {
                return 0;
            }
    
            return outerAngleDegrees;
        } else {
            if(this.leftNode.isAllowed){
                return 0;
            }
            if (this.isAllowed) {
                this.switchColor = true;
                return 0;
            } else {
                return undefined; // Not enough nodes
            }
        }}else {return undefined}
    }

    public drawLine() {
        this.clear();
        [this.x1, this.y1, this.x2, this.y2] = this.setLineCoords();
        this.lineStyle(1, 0x1a1a1a);
        let theta = Math.atan2((this.y2 - this.y1), (this.x2 - this.x1)); // Winkel
        theta *= 180 / Math.PI; // radius zu Winkel, range (-180, 180]
        // if (theta < 0) theta = 360 + theta; // range [0, 360)
        this.length = euclideanDistance(this.x1, this.x2, this.y1, this.y2)
        this.distance = this.length;

        // Determine the direction of the wall
        let unpaintedThickness = this.thickness / 1.5;
        let paintedThickness = this.thickness / 3;



        const isWallColorSwitched = useStore.getState().isWallColorSwitched;

    const xDiff = this.rightNode.position.x - this.leftNode.position.x;
    const yDiff = this.rightNode.position.y - this.leftNode.position.y;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
        // More horizontal movement
        if (xDiff > 0) { // Drawing to the right
            if (this.switchColor) {
                if(isWallColorSwitched){
                    this.beginFill(0xFF9900).drawRect(0, 0, this.length, paintedThickness).endFill();
                    this.beginFill(0x000000).drawRect(0, paintedThickness, this.length, unpaintedThickness).endFill();
                }else{
                    this.beginFill(0xFF9900).drawRect(0, unpaintedThickness, this.length, paintedThickness).endFill();
                    this.beginFill(0x000000).drawRect(0, 0, this.length, unpaintedThickness).endFill();
                }
            } else if (isWallColorSwitched || this.switchColor) {
                // Paint top side
                this.beginFill(0xFF9900).drawRect(0, unpaintedThickness, this.length, paintedThickness).endFill();
                this.beginFill(0x000000).drawRect(0, 0, this.length, unpaintedThickness).endFill();
            } else {
                // Paint bottom side
                this.beginFill(0xFF9900).drawRect(0, 0, this.length, paintedThickness).endFill();
                this.beginFill(0x000000).drawRect(0, paintedThickness, this.length, unpaintedThickness).endFill();
            }
        } else { // Drawing to the left
            if (this.switchColor) {
                if(isWallColorSwitched){
                    this.beginFill(0xFF9900).drawRect(0, unpaintedThickness, this.length, paintedThickness).endFill();
                    this.beginFill(0x000000).drawRect(0, 0, this.length, unpaintedThickness).endFill();
                }else{
                    this.beginFill(0xFF9900).drawRect(0, 0, this.length, paintedThickness).endFill();
                    this.beginFill(0x000000).drawRect(0, paintedThickness, this.length, unpaintedThickness).endFill();
                }
            } else if (isWallColorSwitched || this.switchColor) {
                // Paint bottom side
                this.beginFill(0xFF9900).drawRect(0, 0, this.length, paintedThickness).endFill();
                this.beginFill(0x000000).drawRect(0, paintedThickness, this.length, unpaintedThickness).endFill();
            } else {
                // Paint top side
                this.beginFill(0xFF9900).drawRect(0, unpaintedThickness, this.length, paintedThickness).endFill();
                this.beginFill(0x000000).drawRect(0, 0, this.length, unpaintedThickness).endFill();
            }
        }
    } else {
        // More vertical movement
        if (yDiff > 0) { // Drawing downwards in screen-space
            if (this.switchColor) {
                if(isWallColorSwitched){
                    this.beginFill(0xFF9900).drawRect(0, 0, this.length, paintedThickness).endFill();
                    this.beginFill(0x000000).drawRect(0, paintedThickness, this.length, unpaintedThickness).endFill();
                }else{
                    this.beginFill(0xFF9900).drawRect(0, unpaintedThickness, this.length, paintedThickness).endFill();
                    this.beginFill(0x000000).drawRect(0, 0, this.length, unpaintedThickness).endFill();
                }
            } else if (isWallColorSwitched || this.switchColor) {
                // Paint left side
                this.beginFill(0xFF9900).drawRect(0, unpaintedThickness, this.length, paintedThickness).endFill();
                this.beginFill(0x000000).drawRect(0, 0, this.length, unpaintedThickness).endFill();
            } else {
                // Paint right side
                this.beginFill(0xFF9900).drawRect(0, 0, this.length, paintedThickness).endFill();
                this.beginFill(0x000000).drawRect(0, paintedThickness, this.length, unpaintedThickness).endFill();
            }
        } else { // Drawing upwards in screen-space
            if (this.switchColor) {
                if(isWallColorSwitched){
                    this.beginFill(0xFF9900).drawRect(0, unpaintedThickness, this.length, paintedThickness).endFill();
                    this.beginFill(0x000000).drawRect(0, 0, this.length, unpaintedThickness).endFill();
                }else{
                    this.beginFill(0xFF9900).drawRect(0, 0, this.length, paintedThickness).endFill();
                    this.beginFill(0x000000).drawRect(0, paintedThickness, this.length, unpaintedThickness).endFill();
                }
            } else if (isWallColorSwitched || this.switchColor) {
                // Paint right side
                this.beginFill(0xFF9900).drawRect(0, 0, this.length, paintedThickness).endFill();
                this.beginFill(0x000000).drawRect(0, paintedThickness, this.length, unpaintedThickness).endFill();
            } else {
                // Paint left side
                this.beginFill(0xFF9900).drawRect(0, unpaintedThickness, this.length, paintedThickness).endFill();
                this.beginFill(0x000000).drawRect(0, 0, this.length, unpaintedThickness).endFill();
            }
        }
    }
        this.position.set(this.x1, this.y1)
        this.angle =theta

        this.leftNode.angle = theta;
        this.rightNode.angle = theta;
        
        let previousx = this.wewantnewnode()[1]?.x;
        let currentx = this.wewantnewnode()[2]?.x;
        this.width = this.calculateLineWidth();

         
        // lets get the angle value printed on a label 
        if(this.letsgetingstartedanglefunction() !== undefined && 
        (this.wewantnewnode()[2]?.x === this.x2 || this.wewantnewnode()[2]?.x === this.x1 || (this.isAllowed || this.leftNode.isAllowed || this.rightNode.isAllowed))) {
            //  * What we checked here is if the current node is the last node in the array so that we can print the angle label in the right corner
            if(currentx > previousx){
                 

                this.anglelabel.position.x = this.width / 20;
            }else {
                 


                this.anglelabel.position.x = this.width - 20;
            }
            this.angle2 = this.letsgetingstartedanglefunction();
             

            if(this.angle2 === 0 && !(this.isAllowed || this.leftNode.isAllowed || this.rightNode.isAllowed)){
                 
                this.anglelabel.visible = false;
            }
             

            this.anglelabel.update2(this.angle2)
            this.anglelabel.angle = 360 - theta
            this.anglelabel.position.y = 15
            // this.anglelabel.position.x = this.width - 20;
            this.anglelabel.zIndex = 998;
        }else {          
             
            FloorPlan.Instance.toggleLabel();} 
        
        this.label.update(this.distance);
         

        // this.label.update(theta);
        this.label.position.x = this.width / 2;
        this.label.angle = 360 - theta
        // this.label.position.y = 25;
        this.label.zIndex = 998;
    }

    private onRightDown(ev: InteractionEvent) {
        ev.stopPropagation();
        this.setIsExterior(!this.isExteriorWall);
        return
    }


    private onMouseMove(ev: InteractionEvent) {
        if (!this.dragging) {
            return;
        }
        let currentPoint = ev.data.global;
        let delta = {
            x: currentPoint.x - this.mouseStartPoint.x,
            y: currentPoint.y - this.mouseStartPoint.y
        }

        this.leftNode.setPosition(this.startLeftNode.x + delta.x, this.startLeftNode.y + delta.y);
        this.rightNode.setPosition(this.startRightNode.x + delta.x, this.startRightNode.y + delta.y);
    }

    private onMouseUp(ev: InteractionEvent) {
        this.dragging = false;
            // Update the previous position
        this.previousPosition = { x: this.mouseStartPoint.x, y: this.mouseStartPoint.y };
        
        return;
    }

    private onMouseDown(ev: InteractionEvent) {
        ev.stopPropagation();

        let coords = {x:viewportX(ev.data.global.x), y:viewportY(ev.data.global.y)}
        const state = useStore.getState()

        if (state.activeTool === Tool.Remove) {

            let action = new DeleteWallAction(this);
            ActionHistory.Instance.execute(action);
        }

        if (state.activeTool === Tool.WallAdd) {
            const addNode = new AddNodeAction(this, coords);
            addNode.execute();
        }

        if (state.activeTool === Tool.Edit && !this.dragging) {
            this.dragging = true;
            this.mouseStartPoint.x = viewportX(ev.data.global.x);
            this.mouseStartPoint.y = viewportY(ev.data.global.y);
            this.startLeftNode.x = this.leftNode.position.x;
            this.startLeftNode.y = this.leftNode.position.y;

            this.startRightNode.x = this.rightNode.position.x;
            this.startRightNode.y = this.rightNode.position.y;

            return;
        }

    }

}
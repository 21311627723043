import { FloorPlan } from "../objects/FloorPlan";
import { Wall } from "../objects/Walls/Wall";
import { WallNode } from "../objects/Walls/WallNode";
import { Action } from "./Action";
import { AddWallManager } from "./AddWallManager";
import {useStore} from  "../../../stores/EditorStore"
import { snap } from "../../../helpers/ViewportCoordinates";
import { Point } from "../../../helpers/Point";
import { DeleteWallNodeAction } from "./DeleteWallNodeAction";
// Add node to FloorPlan. if clicked on screen, just add it. otherwise, add it to the wall.
export class AddNodeAction implements Action {
    private wall:Wall;
    private coords:Point;
    private receiver:FloorPlan;
    private nodeid: number;
    private oldnode: WallNode;
    private oldx: number;
    private oldy: number;

    constructor(wall?:Wall, coords?:Point) {
        if (wall) {
            this.wall = wall;
        }
        if (coords) {
            this.coords = coords;
        }
        this.receiver = FloorPlan.Instance;
    }

    // public execute() {
    //     let node:WallNode;

    //     if (useStore.getState().snap == true) {
    //         this.coords.x = snap(this.coords.x)
    //         this.coords.y = snap(this.coords.y)
    //     }
    //     if (this.wall) {
    //         node = this.receiver.addNodeToWall(this.wall, this.coords);
    //         if (node == null) {
    //             return;
    //         }
    //     } else {
    //         if (!AddWallManager.Instance.checkStep(this.coords)) {
    //             return;
    //         }
    //         node = this.receiver.addNode(this.coords.x, this.coords.y)
        
    //     }
    //     AddWallManager.Instance.step(node);
    //     this.receiver.actions.push(this);
    // }

    //! This is the new execute function try #1 
    public execute(): Promise<void> {
        return new Promise((resolve, reject) => {
            let node: WallNode;
    
            if (useStore.getState().snap === true) {
                this.coords.x = snap(this.coords.x)
                this.coords.y = snap(this.coords.y)
            }
            
            // Check if the new node is on an already drawn wall
            for (let wall of FloorPlan.Instance.getWallNodeSeq().getWalls()) {
                if (wall.isPointOnWall({x: this.coords.x, y: this.coords.y})) {
                    return; // If the new node is on an existing wall, do not add it and return
                }
            }
            if (this.wall) {
                
                node = this.receiver.addNodeToWall(this.wall, this.coords);
                
                if( node){
                    this.nodeid = node.getId();
                }
                if (node === null) {
                    resolve();
                    return;
                }
            } else {
                if (!AddWallManager.Instance.checkStep(this.coords)) {
                    resolve();
                    return;
                }
                node = this.receiver.addNode(this.coords.x, this.coords.y)
                this.nodeid = node.getId();
                this.oldnode = node;
                this.oldx = node.getPosition().x;
                this.oldy = node.getPosition().y;
            }
    
            AddWallManager.Instance.step(node);
            this.receiver.actions.push(this);
            resolve();
        });
    }

    public undo() {
        if (this.nodeid) {
            let deletenode = new DeleteWallNodeAction(this.nodeid);
            deletenode.execute();
        }
        const actionIndex = this.receiver.actions.indexOf(this);
        if (actionIndex > -1) {
            this.receiver.actions.splice(actionIndex, 1);
        }
    }
    public redo() {
        if (this.nodeid) {
            let addNode = new AddNodeAction(undefined, {x: this.oldx, y: this.oldy});
            addNode.execute();
            let allnodes = FloorPlan.Instance.getWallNodeSeq().getWallNodes();
            if (allnodes.size > 0) {
                let lastNode = Array.from(allnodes.values()).pop();
                lastNode.setId(this.nodeid);
            }
            
            
        }
    }
}
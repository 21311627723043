import { useRef, useState } from 'react';
import { Navbar, Group } from '@mantine/core';
import {
  ArrowDownSquare,
  DeviceFloppy,
  Upload,
  Eye,
  Eraser,
  Dimensions,
  Printer,
  Table,
  TableOff,
  Tag,
  Edit,
  Line,
  Trash,
  ArrowBackUp,
  ArrowForwardUp,
  SwitchHorizontal,
  Check
} from 'tabler-icons-react';
import { cleanNotifications, showNotification } from '@mantine/notifications';
import { useStore } from "../../stores/EditorStore";
import { LoadAction } from '../../editor/editor/actions/LoadAction';
import { SaveAction } from '../../editor/editor/actions/SaveAction';
import { SalesforceAction } from '../../editor/editor/actions/SalesforceAction';
import { Tool } from '../../editor/editor/constants';
import { PrintAction } from '../../editor/editor/actions/PrintAction';
import { ToggleLabelAction } from '../../editor/editor/actions/ToggleLabelAction';
import { NavbarLink } from '../NavbarLink';
import { HelpDialog } from '../HelpDialog';
import { ClearAction } from '../../editor/editor/actions/ClearAction';
import { RoundingAction } from '../../editor/editor/actions/RoundingAction';
import { TransformLayer } from '../../editor/editor/objects/TransformControls/TransformLayer';
import { AddWallManager } from '../../editor/editor/actions/AddWallManager';
import { ActionHistory } from '../../editor/editor/actions/ActionHistory';
import { ConfirmationModal } from '../ConfirmationModal';
import { Catalog } from '../Catalog';
import { IconCheck } from '@tabler/icons-react';


const modes = [
  { icon: Line, label: 'Zeichnen', tool: Tool.WallAdd},
  { icon: Eye, label: 'Ansehen', tool: Tool.View },
  { icon: Edit, label: 'Bearbeiten', tool: Tool.Edit },
  { icon: Eraser, label: 'Löschen', tool: Tool.Remove },
];




export function ToolNavbar() {
  const [active, setActive] = useState(0);

  const { setTool } = useStore()
  const { setSnap, snap } = useStore()
  const { setColoring, isWallColorSwitched } = useStore()

  const [modalOpen, setModalOpen] = useState(false);
  const [CatalogueOpen, setCatalogueOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const openCatalogue = () => {
    setCatalogueOpen(true);
  };

  const closeCatalogue = () => {
    setCatalogueOpen(false);
  }

  const closeModal = () => {
    setModalOpen(false);
  };

  const fileRef = useRef<HTMLInputElement>();

  const toolModes = modes.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={index === active}
      onClick={() => {
        setActive(index)

        setTool(link.tool)
      }
      }
    />
  ));

  const handleChange = async (e: any) => {

    let resultText = await e.target.files.item(0).text();

    let action = new LoadAction(resultText);
    action.execute();

  };



  return (<div style={{ position: 'absolute' }}>
    <Navbar height="100vh" width={{ base: 70 }} p="md">

      <Navbar.Section grow>
        <Group direction="column" align="center" spacing={0}>
          {toolModes}
          {/* <NavbarLink icon={Trash} label="Abkanten" onClick={() => {
            let action = new RoundingAction();
            ActionHistory.Instance.execute(action);
            TransformLayer.Instance.deselect();
            AddWallManager.Instance.unset();
          }} /> */}
          <NavbarLink icon={SwitchHorizontal} label="Farbseite wechseln" onClick={() => {
            setColoring(!isWallColorSwitched);
            cleanNotifications();
            showNotification({
              message: 'Farbseite wurde jetzt gewechselt!',
              color: "green",
              icon: (Check ? <IconCheck /> : <IconCheck />)
            })
          }} />
        </Group>

      </Navbar.Section>
      <Navbar.Section grow >
        <Group direction="column" align="center" spacing={0}>
        <NavbarLink icon={ArrowBackUp} label="Rückgängig" onClick={() => {
            setTool(Tool.WallAdd);
            ActionHistory.Instance.undo();
            cleanNotifications();
            showNotification({
              title: 'Rückgängig',
              message: "Letzte Aktion Rückgängig gemacht.",
            })
          }} />
          <NavbarLink icon={ArrowForwardUp} label="Wiederholen" onClick={() => {
            setTool(Tool.WallAdd);
            ActionHistory.Instance.redo();
            cleanNotifications();
            showNotification({
              title: 'Rückgängig',
              message: "Letzte Aktion Rückgängig gemacht.",
            })
          }} />
          <NavbarLink newicon="IconRulerMeasure" label="Messen" onClick={() => {
            setTool(Tool.Measure);
            cleanNotifications();
            showNotification({
              title: '📐 Messen',
              message: "Klicken und ziehen um zu messen.",
            })
          }} />
          <NavbarLink icon={ArrowDownSquare} label="Raster verwenden" onClick={() => {
            setSnap(!snap);
            cleanNotifications();
            showNotification({
              message: 'Raster jetzt verwenden ' + (snap ? "Aus" : "An"),
              icon: (snap ? <Table /> : <TableOff />)
            })
          }} />
          <NavbarLink icon={Dimensions} label="Längen ein-/ausblenden" onClick={() => {
            let action = new ToggleLabelAction();
            action.execute();
            cleanNotifications();
            showNotification({
              message: 'Längen ein-/ausblenden',
              icon: <Tag />
            })
          }} />
          <HelpDialog />
        </Group>
      </Navbar.Section>
      <Navbar.Section>
        <Group direction="column" align="center" spacing={0}>
          <NavbarLink icon={Trash} label="Zerstören" onClick={() => {
            let action = new ClearAction();
            ActionHistory.Instance.execute(action);
          }} />
          <NavbarLink icon={Printer} label="Drucken" onClick={() => {
            let action = new PrintAction();
            action.execute();
          }} />
          <NavbarLink icon={DeviceFloppy} label="Kantteil speichern" onClick={() => {
            // let action = new SaveAction();
            // action.execute();
            openModal();
          }} />
          <NavbarLink icon={DeviceFloppy} label="Kantteil in Salesforce abschließen" onClick={() => {
            let action = new SalesforceAction();
            action.execute();
          }} />

          <NavbarLink icon={Trash} label="Katalog öffnen" onClick={() => {
            // let action = new SaveAction();
            // action.execute();
            openCatalogue();
          }} />
          

          <NavbarLink onClick={() => fileRef.current.click()} icon={Upload} label="Kantteil laden" />
          <input
            ref={fileRef}
            onChange={handleChange}
            multiple={false}
            type="file"
            hidden
          />
        </Group>
      </Navbar.Section>
    </Navbar>
    <ConfirmationModal 
        message="Bist du dir Sicher das du die Zeichnung abschließen und speichern willst?" 
        opened={modalOpen} 
        onConfirm={() => {
          closeModal();
          let action = new SaveAction();
          action.execute();
        }} 
        onCancel={closeModal} 
      />
      <Catalog opened={CatalogueOpen} onCancel={closeCatalogue} />
  </div>
  );
}
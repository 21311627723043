export const endpoint = process.env.REACT_APP_SERVICE_URI ? process.env.REACT_APP_SERVICE_URI : 'https://drawapi.heinl-bauelemente.de/';


export async function getCatalogue() {
    console.log("Fetching catalogue...");
    try {
        const response = await fetch(`${endpoint}api/categories`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to fetch the catalogue:", error);
        return []; 
    }
}


export async function getCatalog() {
    console.log("Fetching catalog data...");
    try {
        const response = await fetch(`${endpoint}api/catalog`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to fetch the catalog:", error);
        return []; 
    }
}


export async function getSubcategories(categoryID) {
    console.log("Fetching Subcategories...");
    try {
        const response = await fetch(`${endpoint}api/category/${categoryID}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to fetch the catalogue:", error);
        return []; 
    }
}


export async function addFolderToParent(parentId, folderData, token) {
    console.log("Adding new folder to parent...");
    try {
        const response = await fetch(`${endpoint}api/folder/add`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({ ...folderData, parentId })
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to add the folder to parent:", error);
        throw error;
    }
}


export async function getPlanById(planId: string) {
    console.log("Fetching plan data...");
    try {
        const response = await fetch(`${endpoint}api/plan/${planId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to fetch the plan:", error);
        return null;
    }
}


export async function addPlan(planData, token) {
    console.log("Adding new plan...");
    try {
        const response = await fetch(`${endpoint}api/plan`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(planData)
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to add the plan:", error);
        throw error;
    }
}


export async function gettoken (username, password) {
    console.log("Fetching token...");
    try {
        const response = await fetch(`${endpoint}api/adminLogin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username: username, password: password})
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to fetch the token:", error);
        return null;
    }
}


export async function deleteFolder(id, token) {
    console.log("Deleting folder...");
    try {
        const response = await fetch(`${endpoint}api/folder/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to delete the folder:", error);
        throw error;
    }
}

export async function deletePlan(id, token) {
    console.log("Deleting plan...");
    try {
        const response = await fetch(`${endpoint}api/plan/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to delete the plan:", error);
        throw error;
    }
}


export async function addPlanToParent(parentId, planData, token) {
    console.log("Adding new plan to parent...");
    try {
        const response = await fetch(`${endpoint}api/plan/add`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({ ...planData, parentId })
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to add the plan to parent:", error);
        throw error;
    }
}

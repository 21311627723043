import { EditorRoot } from '../../editor/EditorRoot';
import { WelcomeModal } from '../WelcomeModal';
import { ToolNavbar } from './ToolNavbar';
import { isMobile } from 'react-device-detect';
import Heinllogo from '../../res/logo.png'
import { Center, Modal, Image } from '@mantine/core';
import { useLocation } from 'react-router-dom';



export function PageLayout() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uuid = queryParams.get('uuid')

    if (isMobile) {
        return <>
            <Modal
                opened={true}
                withCloseButton={false}
                onClose={() => (false)}
            >
                <Center>
                    <Image src={Heinllogo}/>
                </Center>
                Sorry aber Heinl-Drawing ist nur für Desktop PC´s Programmiert.
            </Modal>
        </>
    }

    return (<>

        <WelcomeModal />
        <ToolNavbar></ToolNavbar>

        <EditorRoot uuid = {uuid}/>
    </>
    );
}

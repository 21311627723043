import saveAs from "file-saver";
import { FloorPlan } from "../objects/FloorPlan";
import { Action } from "./Action";
import { ClearAction } from "./ClearAction";
import { AddWallManager } from "./AddWallManager";

export class SaveAction implements Action {

    private receiver: FloorPlan;
    constructor() {
        this.receiver = FloorPlan.Instance;

    }

    public execute() {
        let data = this.receiver.save();
        let blob = new Blob([data], { type: "application/json;charset=utf-8" });
        saveAs(blob, "kantteil.json");
        let action = new ClearAction();
        action.execute();
        AddWallManager.Instance.resetTools()
    }
    public undo() {}
    public redo() {}
}
import { Container, InteractionEvent, Sprite, Text, TextStyle, Texture} from "pixi.js";
import { Point } from "../../../../helpers/Point";
import { METER, Tool} from "../../constants";
import { Wall } from "../Walls/Wall";
import { useStore } from "../../../../stores/EditorStore";


export class Label extends Container {
    text:Text;
    textStyle:TextStyle = new TextStyle({fontFamily : 'Arial', fontSize: 16, fill : 0x000000, align : 'center'});
    textBkg :Sprite = new Sprite(Texture.WHITE); 
    type: string;
    wall: Wall; // Add a Wall property

    constructor(sizeInPixels?: number, wall?: Wall) {
        super();
        if (!sizeInPixels) {
            sizeInPixels = 0;
        }
        if (wall) {
            this.wall = wall; // Set the Wall property if a Wall instance was passed to the constructor
        }
        this.type = '';
        this.text = new Text("", this.textStyle);
        this.update(sizeInPixels);
        this.interactive = true;
        this.buttonMode = false;
        this.addChild(this.textBkg);
        this.addChild(this.text);
        this.pivot.set(this.width / 2, this.height / 2);
        this.zIndex = 1001;
        this.on("toggleLabel", this.toggleLabel);
        this.on('click', this.onClick);
        this.toggleLabel({});
    }
    private checkTools(ev: InteractionEvent) {
        ev.stopPropagation()
        if (ev.data.button === 2 || ev.data.button === 2) {
            return;
        }
        switch (useStore.getState().activeTool) {
            case Tool.WallAdd:
                this.buttonMode = false;
                break;
            case Tool.Edit:
                this.buttonMode = true;
                break;
            case Tool.Measure:
                this.buttonMode = false;
                break;
        }
    }

    // Add a new method to handle the click event
    private onClick() {
        // Create a new HTML input element
        const input = document.createElement('input');
        input.type = 'number';

        if(this.type === 'angle'){
            input.min = '1';
            input.step = '1';
            input.max = '180';
            // Set the initial value to the current label angle
            let numstr = this.text.text.replace('°', '');
            input.value = numstr;
        }else{
            input.min = '10';
            input.step = '1';
            input.max = '3000';
            // Set the initial value to the current label text
            input.value = this.text.text;
        }
        input.size = 100;
        input.autocomplete = 'off';
        input.style.position = 'absolute';
    
    
        // Position the input element over the label
        const rect = this.getGlobalPosition();
        input.style.left = `${rect.x}px`;
        input.style.top = `${rect.y}px`;
    
        // Add the input element to the DOM
        document.body.appendChild(input);
    
        // Focus the input element
        input.focus();
    
        // Flag to indicate whether the Enter key has been pressed
        let enterPressed = false;

        input.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                if(this.type === 'distance'){ 
                    if (this.wall) { // Check if a Wall instance has been set
                        this.wall.lengthlabelgotchanged(Number(input.value)); // Now you can access the Wall instance
                    }
                    this.update (parseFloat(input.value));
                    input.value = null;

                }
                else if(this.type === 'angle'){
                    if(!this.wall.switchColor){
                        this.wall.anglelablegotchanged(Number(input.value)); // Now you can access the Wall instance
                        this.update2(parseFloat(input.value));
                        input.value = null;
                    }
                }
                enterPressed = true;
                event.preventDefault();
                document.body.removeChild(input);
            }
        });

        input.addEventListener('blur', () => {
            if (!enterPressed) {
                input.value = null;
                document.body.removeChild(input);
            }
        });
    }

    private toggleLabel(ev:any) {
    }
    public update(sizeInPixels:number) {
        this.type = 'distance';
        this.text.text = this.toMeter(sizeInPixels*2);
        this.textBkg.width = this.text.width;
        this.textBkg.height = this.text.height;


    }

    public update2(sizeInPixels:number) {
        this.type = 'angle';
        this.text.text = Math.round(sizeInPixels).toFixed(0)+'°';
        this.textBkg.width = this.text.width;
        this.textBkg.height = this.text.height;


    }

    
    public makeitinvisible() {
        this.text.visible = false;
        this.textBkg.visible = false;
    }

    public updatePos(pos:Point, sizeInPixels:number) {
        this.position.set(pos.x, pos.y)
        this.update2(sizeInPixels)
    }

    private toMeter(size:number) {
        size = Math.abs(size) / METER;

        // truncating to the 2nd decimal
        const sizeLabel = (Math.round((size) * 100) ).toFixed(0)

        return sizeLabel
    }    
}
